import {
    GET_ALL_ABOUT,
    ADD_ABOUT,
    UPDATE_ABOUT,
    DELETE_ABOUT
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();


export const onGetAllAbout = (data: any) => {
    return (dispatch: any) => {
        client.getAllAbouts(data).then((response) => {
            dispatch({
                type: GET_ALL_ABOUT,
                result:  response.abouts,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
            })
    };
};

export const onAddAbout = (about: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_ABOUT,
            data: about
        });
    }
};


export const onUpdateAbout = (about: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_ABOUT,
                data: about
            });
        } catch (error) {
            errorNotify("error")
        }
        
    }
};

export const onDeleteAbout = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_ABOUT,
            data: data
        });
    };
};


