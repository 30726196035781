import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  Briefcase, Cpu, DollarSign, FileText, FolderMinus, Layers, Monitor,
  UserCheck, CheckSquare, BookOpen, Package, CheckCircle, Users, Smile,
  HelpCircle, Wind, Settings, Sliders, MessageCircle, Gift, Award
} from 'react-feather';

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);
  const user = useSelector((state: any) => state.auth.authUser)
  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };


  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
    >

      <Menu.Item key="dashboard">
        <Link to="/dashboard">
          <span style={{ color: '#aaa' }}>
            <IntlMessages id="sidebar.dashboard" />
          </span>
        </Link>
      </Menu.Item>


      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="users"
        title={<IntlMessages id="sidebar.users" />}
      >
        <Menu.Item key="administrators/list">
          <Link to="/administrators/list">
            <i className="icon icon-auth-screen" />
            <span>
              <IntlMessages id="sidebar.administrators" />
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="developers/list">
          <Link to="/developers/list">
            <i className="icon icon-profile2" />
            <span>
              <IntlMessages id="sidebar.developers" />
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="companies/list">
          <Link to="/companies/list">
            <i className="icon icon-company" />
            <span>
              <IntlMessages id="sidebar.companies" />
            </span>
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="works"
        title={<IntlMessages id="sidebar.works" />}
      >
        <Menu.Item key="projects/list">
          <Link to="/projects/list">
            <FolderMinus size={19} className="icon" />
            <span>
              <IntlMessages id="sidebar.works" />
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="missions/list">
          <Link to="/missions/list">
            <Layers size={19} className="icon" />
            <span>
              <IntlMessages id="sidebar.missions" />
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="contracts/list">
          <Link to="/contacts/list">
            <FileText size={19} className="icon" />
            <span>
              <IntlMessages id="sidebar.contracts" />
            </span>
          </Link>
        </Menu.Item>
      </SubMenu>
      {user.role === "master" &&
        <SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="finances"
          title={<IntlMessages id="sidebar.finances" />}
        >
          <Menu.Item key="finances/list">
            <Link to="/finances/list">
              <i className="icon icon-transfer" />
              <span>
                <IntlMessages id="sidebar.finances" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="invoices/list">
            <Link to="/invoices/list">
              <DollarSign size={19} className="icon" />
              <span>
                <IntlMessages id="sidebar.invoices" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
      }
      <Menu.Item key="recruitment-process">
        <Link to="/recruitment-process">
          <span style={{ color: '#aaa' }}><IntlMessages id="sidebar.recruitmentProcesss" /></span>
        </Link>
      </Menu.Item>
      <Menu.Item key="companies-messages">
        <Link to="/companies-messages">
          <span style={{ color: '#aaa' }}>
            <IntlMessages id="sidebar.companiesMessages" />
          </span>
        </Link>
      </Menu.Item>


      <Menu.Item key="offers">
        <Link to="/offers">
          <span style={{ color: '#aaa' }}>
            <IntlMessages id="sidebar.offers" /></span>
        </Link>
      </Menu.Item>



      <SubMenu
        key="globals"
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <span>
            {" "}

            <span>
              <IntlMessages id="sidebar.globals" />
            </span>
          </span>
        }
      >
        <Menu.Item key="technologies/list">
          <Link to="/technologies/list">
            <Cpu size={19} className="icon" />
            <span>
              <IntlMessages id="sidebar.technologies" />
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="engineer-types">
          <Link to="/engineer-types">
            <UserCheck size={19} className="icon" />
            <span><IntlMessages id="sidebar.engineer_types" /></span>
          </Link>
        </Menu.Item>
        <Menu.Item key="recruitment-process-parameters">
          <Link to="/recruitment-process-parameters">
            <Settings size={19} className="icon" />
            <span><IntlMessages id="sidebar.recruitmentProcessParameters" /></span>
          </Link>
        </Menu.Item>

        <Menu.Item key="text-messages">
          <Link to="/text-messages">
            <MessageCircle size={19} className="icon" />
            <span><IntlMessages id="sidebar.textMessage" /></span>
          </Link>
        </Menu.Item>
      </SubMenu>

      {user.role === "master" &&
        <SubMenu
          key="website"
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          title={
            <span>
              {" "}

              <span>
                <IntlMessages id="sidebar.website" />
              </span>
            </span>
          }
        >

          <Menu.Item key="headers">
            <Link to="/headers"><BookOpen size={19} className="icon" />
              <span><IntlMessages id="sidebar.headers" /></span>
            </Link>
          </Menu.Item>
          <Menu.Item key="terms">
            <Link to="/terms"><BookOpen size={19} className="icon" />
              <span><IntlMessages id="sidebar.terms" /></span>
            </Link>
          </Menu.Item>

          <Menu.Item key="proposals">
            <Link to="/proposals"><Package size={19} className="icon" />
              <span><IntlMessages id="sidebar.proposals" /></span>
            </Link>
          </Menu.Item>

          <Menu.Item key="choice-reason">
            <Link to="/choice-reason"><CheckCircle size={19} className="icon" />
              <span><IntlMessages id="sidebar.choiceReasons" /> </span>
            </Link>
          </Menu.Item>

          <Menu.Item key="testimonials">
            <Link to="/testimonials"><Smile size={19} className="icon" />
              <span><IntlMessages id="sidebar.testimonials" /></span>
            </Link>
          </Menu.Item>

          <Menu.Item key="process">
            <Link to="/process"><CheckSquare size={19} className="icon" />
              <span><IntlMessages id="sidebar.processs" /></span>
            </Link>
          </Menu.Item>

          <Menu.Item key="faqs">
            <Link to="/faqs"><HelpCircle size={19} className="icon" />
              <span><IntlMessages id="sidebar.faqs" /></span>
            </Link>
          </Menu.Item>

          <Menu.Item key="teams">
            <Link to="/teams"><Users size={19} className="icon" />
              <span><IntlMessages id="sidebar.teams" /></span>
            </Link>
          </Menu.Item>

          <Menu.Item key="abouts">
            <Link to="/abouts"><Wind size={19} className="icon" />
              <span><IntlMessages id="sidebar.abouts" /></span>
            </Link>
          </Menu.Item>


          <Menu.Item key="partners">
            <Link to="/partners">
              <span>
                <IntlMessages id="sidebar.patners" /></span>
            </Link>
          </Menu.Item>


        </SubMenu>}
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;
