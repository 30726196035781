import {
    GET_ALL_PROPOSAL,
    ADD_PROPOSAL,
    UPDATE_PROPOSAL,
    DELETE_PROPOSAL
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllProposal = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllProposals(data).then((response) => {
           
            dispatch({
                type: GET_ALL_PROPOSAL,
                result: response.proposals,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddProposal = (proposal: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_PROPOSAL,
            data: proposal
        });
    }
};


export const onUpdateProposal = (proposal: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_PROPOSAL,
                data: proposal
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteProposal = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_PROPOSAL,
            data: data
        });
    };
};


