import { client } from '@bidiffy/general-backend-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { userSignOut } from '../appRedux/actions';
import { RootState } from '../appRedux/store';

export const useBidiffySdkClient = () => {
    const refreshToken = useSelector((state: RootState) => state.auth.refreshToken);
    const dispatch = useDispatch();

    if (refreshToken != null && client.options.refreshToken !== refreshToken) {
        client.configure({
            ...client.options,
            refreshToken,
            appLogoutCallback :() => {
                // console.log("DECONNECTION");
                dispatch(userSignOut());
            }
        })
    }
    return client;
}