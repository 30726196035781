import React, { useCallback, useEffect, useState } from "react";
import NotificationItem from "./NotificationItem";
import { notifications } from "./data";
import CustomScrollbars from 'util/CustomScrollbars'
import Auxiliary from "util/Auxiliary";
import { Client } from "@bidiffy/general-backend-sdk";
import io from 'socket.io-client';
import { withSuccess } from "antd/lib/modal/confirm";
import Item from "antd/lib/list/Item";
import { useSelector } from "react-redux";
import { RootState } from "../../appRedux/store";
import moment from "moment";

const MailNotification = () => {
  const notifications = useSelector(({ notifications }: RootState) => {
    const data = notifications?.notifications;
    // console.log("DATA: ", data)
    if (data == null ) return [];
    return data.map((item: any) => ({
      id: item._id,
      // image: "https://via.placeholder.com/150",
      image: null,
      name: item.title,
      time: moment(item.createdAt).fromNow(),
      message: item.text,
      badge: 0,
      user: item.byUser,
      developerId: item.developerId
    }))
  });
  return (
    <Auxiliary>
      <div className="gx-popover-header" >
        <h3 className="gx-mb-0"> Notifications ({notifications?.length}) </h3>
        {/*<i className="gx-icon-btn icon icon-charvlet-down" />*/}
      </div>
      <CustomScrollbars className="gx-popover-scroll" >
        <ul className="gx-sub-popover" >
          {
            notifications.map((notification:any, index: number) => <NotificationItem key={index}
              notification={notification} />)
          }
        </ul>
      </CustomScrollbars>
    </Auxiliary>
  )
};

export default MailNotification;

