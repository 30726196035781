import {
    GET_MISSION,
    GET_ONE_MISSION
    } from "../../constants/ActionTypes";
    
    const INIT_STATE = {
      missions: [],
      selectedItem: null
    };


    const AttachmentsListFormater = (data: any) => {
      const tmp = [{}]
      tmp.pop()
      if (data) {
        data.map((el: any) => {
          tmp.push({
            uid: el._id,
            name: el.title,
            title: el.title,
            status: "done",
            url: el.url,
          })
        })
      }
    
      return tmp
    }
    
    
    export const missionListFormater = (data: any) => {
      const results = [{}]
      results.pop()
      data.map((projetData: any) => {
        results.push(missionFormat(projetData))
      })
      return results
    }
    
      export const missionFormat = (missionData:any) => {
      return {
        ...missionData,
        attachments: AttachmentsListFormater(missionData?.attachments||[])
      }
    } 
    
    const MissionReducer = (state = INIT_STATE, action:any) => {
      switch (action.type) {
        case GET_MISSION: {
          return {
            ...state,
            missions:missionListFormater(action.payload.results)
          }
        }
    
        case GET_ONE_MISSION: {
          return {
            ...state,
            selectedItem: action.payload
          }
        }
        default:
          return state;
      }
    }
    
    export default MissionReducer;
    