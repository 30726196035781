import { LOAD_NOTIFICATIONS, SET_SOCKET, UPDATE_NOTIFICATIONS } from "../../constants/ActionTypes";
import { errorNotify, successNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetNotifications = async () => {
  const data: any = await client.getNotifications({});
  // console.log("notifications ", data);
  return {
    type: LOAD_NOTIFICATIONS,
    data: data,
  }
};

export const onNotificationSocket = (data: any) => {
  return {
    type: SET_SOCKET,
    data: data,
  };
};

export const onUpdateNotifications = (data: any) => {
  return {
    type: UPDATE_NOTIFICATIONS,
    data: data,
  }
}