import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import technology from './Technologies';
import EngineerTypes from './EngineerTypes';
import ProjectsReducer from './Projects';
import MissionReducer from './Mission';
import CompanyReducer from './Company'
import AdminReducer from './Admin';
import ContratReducer from './Contrats';
import FinanceReducer from './Finance';
import ProposalReducer  from './Proposal';
import ChoiceReasonReducer from './ChoiceReason';
import TestimonialReducer from './Testimonial';
import ProcessReducer from './Process';
import FaqReducer from './Faq';
import TeamReducer from './Team';
import AboutReducer from './About';
import TermReducer from './Term';
import RecruitmentProcessParameterReducer from './RecruitmentProcessParameter';
import RecruitmentProcessReducer from './RecruitmentProcess';
import TextMessageReducer from './TextMessage';
import OfferReducer from './Offer';
import PatnerReducer from './Patner';
import HeaderReducer from './Header';
import NotificationsReducer from './Notification';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  technology: technology,
  engineerTypes: EngineerTypes,
  projects:ProjectsReducer,
  missions: MissionReducer,
  companies: CompanyReducer,
  admins: AdminReducer,
  contrats: ContratReducer,
  finances:FinanceReducer,
  proposals: ProposalReducer,
  choiceReasons: ChoiceReasonReducer,
  testimonials: TestimonialReducer,
  processs: ProcessReducer,
  faqs: FaqReducer,
  teams : TeamReducer,
  abouts : AboutReducer,
  terms : TermReducer,
  recruitmentProcessParameters : RecruitmentProcessParameterReducer,
  recruitmentProcesss : RecruitmentProcessReducer,
  textMessages: TextMessageReducer,
  offers: OfferReducer,
  patners: PatnerReducer,
  headers: HeaderReducer,
  notifications: NotificationsReducer,
});

export default createRootReducer
