import {
    GET_ALL_TEAM,
    ADD_TEAM,
    UPDATE_TEAM,
    DELETE_TEAM
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllTeam = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllTeams(data).then((response) => {
           
            dispatch({
                type: GET_ALL_TEAM,
                result: response.teams,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddTeam = (team: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_TEAM,
            data: team
        });
    }
};


export const onUpdateTeam = (team: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_TEAM,
                data: team
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteTeam = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_TEAM,
            data: data
        });
    };
};


