import {
    GET_ALL_PROCESS,
    ADD_PROCESS,
    UPDATE_PROCESS,
    DELETE_PROCESS
} from "../../constants/ActionTypes";

import { Process, ProcessItem } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    processsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const ProcessListFormater = (data: Process[]) => {
    const results = [{}]
    results.pop()
    data.map((processData: any) => {
        results.push(ProcessFormat(processData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value+'';
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }

  export const ItemFormat = (item: ProcessItem) => {
    if (!item) {
        item = { title:'', description:'',enumeration:''}
    }
    const itemInfo = {
        key:item._id||'',
        title: item.title||'',
        enumeration:item.enumeration||'',
        description: epurator(item.description),
    }

    return itemInfo
}


export const ItemStringListFormater = (data: any) => {
    if(!data){
        data=[]
    }
    const results = [{}]
    results.pop()
    data.map((StringData: any,counter:any) => {
        results.push(ItemStringFormat(StringData,counter+1))
    })
    
    return results
}

export const ItemStringFormat = (item: string, counter:any) => {

    const itemInfo = {
        key:counter,
        description: epurator(item),
    }

    return itemInfo
}

export const ItemListFormater = (data: any) => {
    if(!data){
        data=[]
    }
    const results = [{}]
    results.pop()
    data.map((processData: any) => {
        results.push(ItemFormat(processData))
    })
    
    return results
}

export const ItemStringToArray =(data: {key?:string,description:string}[]) => {
    if(!data){
        data=[]
    }
    const results = ['']
    results.pop()
    data.map((strindData) => {
        results.push(strindData.description)
    })
    
    return results
}



export const ProcessFormat = (processData: Process) => {
    return {
        key: processData._id,
        headtitle:processData.headtitle||'',
        pageName: processData.pageName||'',
        title: processData.title,
        subtitle: processData.subtitle,
        description : processData.description,
        image:processData.image||'',
        itemNb : processData?.steps?.length||0,
        steps:  ItemListFormater(processData?.steps||[]),
        textOnImage: ItemStringListFormater(processData?.textOnImage||[])

    }
}

const ProcessReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_PROCESS: {
            return {
                ...state,
                processsData: ProcessListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_PROCESS:
            const project = ProcessFormat(action.data);
            return {
                ...state,
                processData: updatedArray(state.processsData, project),
                showModal: false
            };

        case DELETE_PROCESS:
            const _PROCESS = action.data;
            return {
                ...state,
                processData: arrayReduce(state.processsData, _PROCESS),
                total: state.total-1,
                showModal: false
            };

        case ADD_PROCESS:
            let processData = !state.processsData ? [] : state.processsData;
            
            processData.unshift(ProcessFormat(action.data));
            return {
                ...state,
                processData: processData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default ProcessReducer;