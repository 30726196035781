const currencyData = [
    {
      code: 'EUR',
      Symbol: '€',
      name: 'Euros',
    },
    {
        code: 'USD',
        Symbol: '$',
        name: 'DolUS Dollarlars',
      },
      {
        code: 'XOF',
        Symbol: 'XOF',
        name: 'CFA Franc',
      },
    // }
  
  ];
  export default currencyData;
  