import { Options, MailToAdmin } from '../utils/DataSchemas'
import request from '../utils/request'

export default class GlobalService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }


  async sendMailToAdmin(data: MailToAdmin) {
    const requestOptions: Options = {
        ...this.options,
        data: data
      }
      return request('POST', `recruitmentProcesss/mailto/admin`, requestOptions)
  }
}
