import {
  DELETE_CONTACT_SUCCESS,
  GET_All_CONTACT_SUCCESS,
  ON_ADD_CONTACT_SUCCESS,
  UPDATE_CONTACT_SUCCESS
} from "../../constants/ActionTypes";
import { Contrat } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
  contactList: [],
  selectedContact: []
};


const AttachmentsListFormater = (data: any) => {
  const tmp = [{}]
  tmp.pop()
  if (data) {
    data.map((el: any) => {
      tmp.push({
        uid: el._id,
        name: el.title,
        title: el.title,
        status: "done",
        url: el.url,
      })
    })
  }

  return tmp
}


export const contratListFormater = (data: any) => {
  const results = [{}]
  results.pop()
  data.map((projetData: any) => {
    results.push(contratFormat(projetData))
  })
  return results
}

  export const contratFormat = (contratData:any) => {
  return {
    ...contratData,
    attachments: AttachmentsListFormater(contratData?.attachments||[])
  }
} 

const ContactReducer = (state = INIT_STATE, action:any) => {
  switch (action.type) {


    case GET_All_CONTACT_SUCCESS: {
      return {
        ...state,
        contactList: contratListFormater(action.payload),
      }
    }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.map((contact:Contrat) => contact.id === action.payload.id ? action.payload : contact),
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.filter((contact:Contrat) => contact.id !== action.payload.id),
      };

    case ON_ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: action.payload.contact(state.contactList),
      };

    default:
      return state;
  }
}

export default ContactReducer;
