import {
    GET_ALL_CHOICEREASON,
    ADD_CHOICEREASON,
    UPDATE_CHOICEREASON,
    DELETE_CHOICEREASON
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllChoiceReason = (data: any) => {
    return (dispatch: any) => {
        client.getAllChoiceReasons(data).then((response) => {
            dispatch({
                type: GET_ALL_CHOICEREASON,
                result:  response.choiceReasons,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
            })
    };
};

export const onAddChoiceReason = (choiceReason: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_CHOICEREASON,
            data: choiceReason
        });
    }
};


export const onUpdateChoiceReason = (choiceReason: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_CHOICEREASON,
                data: choiceReason
            });
        } catch (error) {
            errorNotify("error")
        }
        
    }
};

export const onDeleteChoiceReason = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_CHOICEREASON,
            data: data
        });
    };
};


