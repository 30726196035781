import {
    GET_ALL_PATNER,
    ADD_PATNER,
    UPDATE_PATNER,
    DELETE_PATNER
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllPatner = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllPatners(data).then((response) => {
            
            dispatch({
                type: GET_ALL_PATNER,
                result: response.patners,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddPatner = (patner: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_PATNER,
            data: patner
        });
    }
};


export const onUpdatePatner = (patner: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_PATNER,
                data: patner
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeletePatner = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_PATNER,
            data: data
        });
    };
};


