import Item from "antd/lib/list/Item";
import moment from "moment";
import {
    GET_ALL_FINANCE,
    ADD_FINANCE,
    UPDATE_FINANCE,
    DELETE_FINANCE
} from "../../constants/ActionTypes";

const INIT_STATE = {
    financesData: <any>[],
    showModal: false,
    total: 0
};

const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const getInvoice = (invoice: any) => {

    if (!invoice) {
        invoice = {}
    }

    const invoiceInfo = {
        invoice__id: invoice._id || '',
        invoice_amount: invoice.amount||'',
        invoice_totalCurrency: invoice.totalCurrency||0,
        invoice_attachments: AttachmentsListFormater(invoice.attachments)||[],
        invoice_country: invoice.country||'',
        invoice_description: invoice.description||'',
        invoice_platformId: invoice.platformId||'',
        invoice_referenceNumber: invoice.referenceNumber||'',
        invoice_tax: invoice.tax||0,
        invoice_title: invoice.title||'',
        invoice_total: invoice.total||0,
        invoice_items: InvoiceItenListFormater(invoice.items||[])
    }

    return invoiceInfo
}
const FinanceListFormater = (data: any) => {
    const results = [{}]
    results.pop()
    data.map((financeData: any) => {
        results.push(FinanceFormat(financeData))
    })
    
    return results
}
const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }

  export const InvoiceItemFormat = (iten: any) => {

    if (!iten) {
        iten = {}
    }

    const itemInfo = {
        key:iten?._id||'',
        amount: iten.amount||0,
        tax: iten.tax||0,
        title: iten.title||'',
        description: epurator(iten.description),
        priceHour:iten.priceHour||0,
        qtyHour:iten.qtyHour||0,
        attachments: AttachmentsListFormater(iten?.attachments)||[],
        itemNb : iten?.attachments?.length||0
        

    }

    return itemInfo
}
export const InvoiceItenListFormater = (data: any) => {
    if(!data){
        data=[]
    }
    const results = [{}]
    results.pop()
    data.map((financeData: any) => {
        results.push(InvoiceItemFormat(financeData))
    })
    
    return results
}

const getusername = (finance: any) => {
    if (finance?.forUser) { 
        if(finance?.forUser?.type_account==="company"){
            return finance?.data?.name
        }else{
        return `${finance?.forUser?.lastname}  ${finance?.forUser?.firstname}` 
        }
    
    }
    return '------'
}

const getUser = (user: any) => {
    if (!user) { return {_id:'',type_account:'----'} }
    return user
}

const AttachmentsListFormater = (data: any) => {
    const tmp = [{}]
    tmp.pop()
    if (data) {
      data.map((el: any) => {
        tmp.push({
          uid: el._id,
          name: el.title,
          title: el.title,
          status: "done",
          url: el.url,
        })
      })
    }
  
    return tmp
  }

  const getFinanceDateFormat = (val:any) => {
      if(!val){
          return val
      }else{
          return moment(val,"YYYY-MM-DD")
      }
  }

export const FinanceFormat = (financeData: any) => {
    const invoice = getInvoice(financeData.invoice)
    return {
        key: financeData._id,
        user: getusername(financeData),
        type: financeData.type|| '',
        status: financeData.status|| false,
        direction: financeData.direction|| '',
        amount: financeData.amount|| 0,
        title: financeData.title|| '',
        currency: financeData.currency|| '',
        isDeleted: financeData.isDeleted|| false,
        platform: financeData.platformId|| '',
        attachments: AttachmentsListFormater(financeData.attachments)|| [],
        forUser: getUser(financeData.forUser)._id||null,
        byUser: getUser(financeData.byUser)._id||null,
        type_account:getUser(financeData.forUser).type_account|| '----',
        description: financeData.description|| '',
        date:financeData.createdAt||'',
        data: financeData.data|| null,
        financeDate:getFinanceDateFormat(financeData.financeDate)||null,
        ...invoice
    }
}

const FinanceReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_FINANCE: {
            return {
                ...state,
                financesData: FinanceListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_FINANCE:
            const project = FinanceFormat(action.data);
            return {
                ...state,
                financesData: updatedArray(state.financesData, project),
                showModal: false
            };

        case DELETE_FINANCE:
            const _FINANCE = action.data;
            return {
                ...state,
                financesData: arrayReduce(state.financesData, _FINANCE),
                total: state.total-1,
                showModal: false
            };

        case ADD_FINANCE:
            let financesData = !state.financesData ? [] : state.financesData;
            
            financesData.unshift(FinanceFormat(action.data));
            return {
                ...state,
                financesData: financesData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default FinanceReducer;