import { Options, EngineerTypeRequest, EngineerType } from '../utils/DataSchemas'
import request from '../utils/request'



export default class EngineerService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (paginationOption: EngineerTypeRequest) => {
    let query = '';

    if (paginationOption.offset !== null) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.search) {
      query
        ? (query += `&search=${paginationOption.search}`)
        : (query += `?search=${paginationOption.search}`)
    }

    return request('GET', `engineer-types/${query}`, this.options)
  }

  async create(data: EngineerType) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `engineer-types/`, requestOptions)
  }

  async getById(id: number) {
    return request('GET', `engineer-types/${id}`, this.options)
  }

  async update(id: number, data: EngineerType) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `engineer-types/${id}`, requestOptions)
  }

  async delete(id: number) {
    return request('DELETE', `engineer-types/${id}`, this.options)
  }
}
