import {
GET_TECHNOLOGIES,
SELECT_ONE
} from "../../constants/ActionTypes";

const INIT_STATE = {
  technologies: [],
  selectedItem: null
};

const TechnologyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TECHNOLOGIES: {
      return {
        ...state,
        technologies: action.payload.results
      }
    }

    case SELECT_ONE: {
      return {
        ...state,
        selectedItem: action.payload
      }
    }
    default:
      return state;
  }
}

export default TechnologyReducer;
