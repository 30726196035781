import {
  GET_ALL_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY
} from "../../constants/ActionTypes";

const INIT_STATE = {
  companiesData: <any>[],
  showModal: false,
  total: 0
};

const updatedArray = (array: any, element: any) => {
  
  let index = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i].key === element.key) {
      
      array[i] = element;
      index = i;
      break;
    }
  }
  return array;
};

const arrayReduce = (array: any, element: any) => {
  let tab = array;
  const index = tab.indexOf(element);
  if (index > -1) {
    tab.splice(index, 1);
  }
  return tab;
};





const GetBankAccount = (bankAccount: any) => {
  if (!bankAccount) {
    bankAccount = {}
  }
  const bankAccountData = {
    title: bankAccount.title || '',
    description: bankAccount.description || '',
    iban: bankAccount.iban || '',
    swiftCode: bankAccount.swiftCode || '',
    accountNumber: bankAccount.accountNumber || '',
    bankName: bankAccount.bankName || '',
    country: bankAccount.country|| ''
  }
  return bankAccountData
}
export const GetUser = (user: any) => {
  

  if (!user) {
    user = {
    role:  'System',
    address:  'Headquarters: Avédji LIMOUSINE -Lome -Togo',
    type_account:  'System',
    media:'',
    email: 'contactbidif@gmail.com',
    phone_number:  '0022892075520',
    lastname:  'BIDIF',
    firstname:  'SARL',
    isDeleted:false,
    bankAccount:{
      country:'Togo'
    },
    active:true
    }
  }

  const userInfo = {
    _id: user._id || '',
    bankAccount: GetBankAccount(user.bankAccount),
    role: user.role || '',
    address: user.address || '',
    type_account: user.type_account || '',
    media: user.media || '',
    email: user.email || '',
    phone_number: user.phone_number || '',
    lastname: user.lastname || '',
    firstname: user.firstname || '',
    isDeleted: user.isDeleted || false,
    active: user?.active
  }

  return userInfo
}
const CompanyListFormater = (data: any) => {
  const results = [{}]
  results.pop()
  data.map((companyData: any) => {
    results.push(CompanyFormat(companyData))
  })
  
  return results
}
const getusername = (user: any) => {
  if (user) { return `${user.lastname}  ${user.firstname}` }
  return ''
}

export const CompanyFormat = (companyData: any) => {
  const user = GetUser(companyData.user)
  return {
    key: companyData._id,
    name: companyData.companyName,
    user: getusername(user),
    isDeleted:companyData.isDeleted||false,
    website: companyData.website,
    other: companyData.other,
    id: companyData._id,
    iduser: user._id,
    firstname: user.firstname,
    email: user.email,
    lastname: user.lastname,
    address: user.address,
    phone: user.phone_number,
    companyName: companyData.companyName,
    bankName: user.bankAccount.bankName,
    bankTitle: user.bankAccount.title,
    bankSwiftCode: user.bankAccount.swiftCode,
    bankIban: user.bankAccount.iban,
    bankAccountNumber: user.bankAccount.accountNumber,
    country: user.bankAccount.country,
    attachement: user.media,
    bankDescription: user.bankAccount.description
  }
}

const CompanyReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GET_ALL_COMPANY: {
      return {
        ...state,
        companiesData: CompanyListFormater(action.result),
        total: action.total
      }
    }

    case UPDATE_COMPANY:
      const project = CompanyFormat(action.data);
      return {
        ...state,
        companiesData: updatedArray(state.companiesData, project),
        showModal: false
      };

    case DELETE_COMPANY:
      const _COMPANY = action.data;
      return {
        ...state,
        companiesData: arrayReduce(state.companiesData, _COMPANY),
        total: state.total-1,
        showModal: false
      };

    case ADD_COMPANY:
      let companiesData = !state.companiesData ? [] : state.companiesData;
      
      companiesData.unshift(CompanyFormat(action.data));
      return {
        ...state,
        companiesData: companiesData,
        total: state.total+1,
        showModal: false
      };

    default:
      
      return state;
  }
}

export default CompanyReducer;