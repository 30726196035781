import {
    GET_ALL_CHOICEREASON,
    ADD_CHOICEREASON,
    UPDATE_CHOICEREASON,
    DELETE_CHOICEREASON
} from "../../constants/ActionTypes";

import { ChoiceReason, SampleItem } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    choiceReasonsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const ChoiceReasonListFormater = (data: ChoiceReason[]) => {
    const results = [{}]
    results.pop()
    data.map((choiceReasonData: any) => {
        results.push(ChoiceReasonFormat(choiceReasonData))
    })
    
    return results
}
const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }

  export const ItemFormat = (item: SampleItem) => {
    if (!item) {
        item = {icon:'',  title:'', description:''}
    }
    const itemInfo = {
        key:item._id||'',
        icon: item.icon||'',
        title: item.title||'',
        description: epurator(item.description),
    }

    return itemInfo
}
export const ItemListFormater = (data: any) => {
    if(!data){
        data=[]
    }
    const results = [{}]
    results.pop()
    data.map((choiceReasonData: any) => {
        results.push(ItemFormat(choiceReasonData))
    })
    
    return results
}


export const ChoiceReasonFormat = (choiceReasonData: ChoiceReason) => {
    return {
        key: choiceReasonData._id,
        title: choiceReasonData.title||'',
        headtitle:choiceReasonData.headtitle||'',
        pageName: choiceReasonData.pageName||'',
        image:choiceReasonData.image||'',
        itemNb : choiceReasonData?.data?.length||0,
        data: ItemListFormater(choiceReasonData?.data)
    }
}

const ChoiceReasonReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_CHOICEREASON: {
            return {
                ...state,
                choiceReasonsData: ChoiceReasonListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_CHOICEREASON:
            const project = ChoiceReasonFormat(action.data);
            return {
                ...state,
                choiceReasonData: updatedArray(state.choiceReasonsData, project),
                showModal: false
            };

        case DELETE_CHOICEREASON:
            const _CHOICEREASON = action.data;
            return {
                ...state,
                choiceReasonData: arrayReduce(state.choiceReasonsData, _CHOICEREASON),
                total: state.total-1,
                showModal: false
            };

        case ADD_CHOICEREASON:
            let choiceReasonData = !state.choiceReasonsData ? [] : state.choiceReasonsData;
            
            choiceReasonData.unshift(ChoiceReasonFormat(action.data));
            return {
                ...state,
                choiceReasonData: choiceReasonData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default ChoiceReasonReducer;