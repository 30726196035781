import { client } from '@bidiffy/general-backend-sdk';
import { userSignOut } from '../appRedux/actions';
import { store } from '../appRedux/store';
export const notificationInTitle = function (data: any) {
    let title = document.title;
    const regex = /^\([0-9]+\)/g;
    title = title.replace(regex, "").trim();
    if (data.length > 0) document.title = '(' + data.length + ') ' + title;
    else document.title = title;
}

export const getBidiffySdkClient = () => {
    const refreshToken = store?.getState()?.auth?.refreshToken;

    if (refreshToken != null && client.options.refreshToken !== refreshToken) {
        client.configure({
            ...client.options,
            refreshToken
        })
    }
    client.options.appLogoutCallback = () => {
        store.dispatch(userSignOut());
    }
    return client;
}