import {
  GET_ALL_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT
} from "../../constants/ActionTypes";

const INIT_STATE = {
  projectsData: <any>[],
  showModal: false,
  total: 0
};

const updatedArray = (array: any, element: any) => {
  
  let index = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i].key === element.key) {
      
      array[i] = element;
      index = i;
      break;
    }
  }
  return array;
};

const arrayReduce = (array: any, element: any) => {
  let tab = array;
  const index = tab.indexOf(element);
  if (index > -1) {
    tab.splice(index, 1);
  }
  return tab;
};


const AttachmentsListFormater = (data: any) => {
  const tmp = [{}]
  tmp.pop()
  if (data) {
    data.map((el: any) => {
      tmp.push({
        uid: el._id,
        name: el.title,
        title: el.title,
        status: "done",
        url: el.url,
      })
    })
  }

  return tmp
}

const GetProjectCompany = (company:any) => {
  let companyInfo = {key:'',name:''}
  if(company){
    companyInfo = {key:company._id,name:company.companyName}
  }
  return companyInfo
}
export const ProjectListFormater = (data: any) => {
  const results = [{}]
  results.pop()
  data.map((projetData: any) => {
    results.push(ProjectFormat(projetData))
  })
  return results
}

  export const ProjectFormat = (projetData:any) => {
  const company = GetProjectCompany(projetData.project.company)
  return {
    key: projetData.project._id,
    company: company.key,
    companyName: company.name,
    cost: projetData.project.cost,
    status: projetData.project.status,
    endDate: projetData.project.endDate || '',
    startDate: projetData.project.startDate || '',
    shortDescription: projetData.project.shortDescription || '',
    description: projetData.project.description || '',
    title: projetData.project.title || '',
    platformId: projetData.project.platformId || '',
    missions: projetData.numberOfMissions || 0,
    attachments: AttachmentsListFormater(projetData.project.attachments)
  }
}

const ProjectsReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GET_ALL_PROJECT: {
      return {
        ...state,
        projectsData: ProjectListFormater(action.result),
        total: action.total
      }
    }

    case UPDATE_PROJECT:
      const project = ProjectFormat(action.data);
      return {
        ...state,
        projectsData: updatedArray(state.projectsData, project),
        showModal: false
      };

    case DELETE_PROJECT:
      const _project = action.data;
      return {
        ...state,
        projectsData: arrayReduce(state.projectsData, _project),
        total: state.total-1,
        showModal: false
      };

    case ADD_PROJECT:
      let projectsData = !state.projectsData ? [] : state.projectsData;
      
      projectsData.unshift(ProjectFormat(action.data));
      return {
        ...state,
        projectsData: projectsData,
        total: state.total+1,
        showModal: false
      };

    default:
      
      return state;
  }
}

export default ProjectsReducer;