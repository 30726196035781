import {
    GET_ALL_COMPANY,
    ADD_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

const client = getBidiffySdkClient();

export const onGetAllCompany = (data: any) => {
    return (dispatch: any) => {
        //Default value for general search
        data["searchType"] = 0
        client.getAllCompany(data).then((response) => {
            dispatch({
                type: GET_ALL_COMPANY,
                result: response.results,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
            })
    };
};

export const onAddCompany = (company: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_COMPANY,
            data: company
        });
    }
};


export const onUpdateCompany = (company: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_COMPANY,
                data: company
            });
        } catch (error) {
            errorNotify("error")

        }

    }
};

export const onDeleteCompany = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_COMPANY,
            data: data
        });
    };
};


