import { Options, PaginationWithPeridoOptions,
  PaginationOptions,PaginationWithExcludedUsers, PaginationByCountry, 
  PaginationByCertified,PaginationWithTechnologies, PaginationWithQuery } from '../utils/DataSchemas'
import request from '../utils/request'

export default class DeveloperService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }


  getAllGeneral = async (
    req: any,
    paginationOption: PaginationOptions
  ) => {
    const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}`
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('GET', `developers/all${query}`, requestOptions)
  }


  getAllGeneralByCountry = async (
    req: any,
    paginationOption: PaginationByCountry
  ) => {
    const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}&country=${paginationOption.country}`
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('GET', `developers/by/country${query}`, requestOptions)
  }

  getAllGeneralByCertified = async (
    req: any,
    paginationOption: PaginationByCertified
  ) => {
    const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}&certified=${paginationOption.certified}`
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('GET', `developers/by/certified${query}`, requestOptions)
  }

  getAll = async (
    req: any,
    paginationOption: PaginationOptions
  ) => {
    const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}`
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('GET', `developers/${query}`, requestOptions)
  }

  getAllAvailableByMissionDate = async (
    req: any,
    paginationOption: PaginationWithPeridoOptions
  ) => {
    
    const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}&startDate=${paginationOption.startDate}&endDate=${paginationOption.endDate}`

    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('GET', `developers/available/by/mission/period${query}`, requestOptions)
  }


  getAllAvailableByContratDate = async (
    req: any,
    paginationOption: PaginationWithPeridoOptions
  ) => {
    
    const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}&startDate=${paginationOption.startDate}&endDate=${paginationOption.endDate}`

    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('GET', `developers/available/by/contrat/period${query}`, requestOptions)
  }

  getById = async (id: string) => {
    return request('GET', `developers/${id}`, this.options)
  }

  getDevMissionAndProjects = async (id: string) => {
    return request('GET', `developers/missions-projects/${id}`, this.options)
  }

  getByUserId = async (id: string) => {
    return request('GET', `developers/user/${id}`, this.options)
  }

  create = async (data: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `developers`, requestOptions)
  }

  update = async (id: string, req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('PATCH', 'developers/' + id, requestOptions)
  }

  delete = async (id: string) => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('DELETE', 'developers/' + id, requestOptions)
  }

  getAllWithExcludeUsers = async (data: PaginationWithExcludedUsers) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `developers/certified/withexcludedusers`, requestOptions)
  }

  getAllGeneralByTechnology = async (data: PaginationWithTechnologies) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `developers/by/technologies`, requestOptions)
  }

  getAllGeneralByQuery= async (data: PaginationWithQuery) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `developers/by/queries`, requestOptions)
  }
}