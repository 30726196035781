import {Mission, MissionPagination, Options} from '../utils/DataSchemas'
import request from '../utils/request'

export default class MissionService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (missionPagination: MissionPagination) => {
    let query = ''

    if (missionPagination.offset !== null) {
      query
        ? (query += `&offset=${missionPagination.offset}`)
        : (query += `?offset=${missionPagination.offset}`)
    }

    if (missionPagination.limit) {
      query
        ? (query += `&limit=${missionPagination.limit}`)
        : (query += `?limit=${missionPagination.limit}`)
    }
    if (missionPagination.search) {
      query
        ? (query += `&search=${missionPagination.search}`)
        : (query += `?search=${missionPagination.search}`)
    }
    query+=`&developer=${missionPagination.developer}&status=${missionPagination.status}`
    

    return request('GET', `missions/${query}`, this.options)
  }

  async create(data: Mission) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `missions/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `missions/${id}`, this.options)
  }

  async update(id: string, data: Mission) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `missions/${id}`, requestOptions)
  }

  async delete(id: string) {
    return request('DELETE', `missions/${id}`, this.options)
  }
}
