import { routerActions } from "connected-react-router";
import { LOAD_NOTIFICATIONS, SET_SOCKET, UPDATE_NOTIFICATIONS } from "../../constants/ActionTypes";

const INIT_STATE = {
  notifications: [],
  socket: false,
};


const NotificationsReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {


    case LOAD_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.data,
      }
    }

    case SET_SOCKET: {
      return {
        ...state,
        socket: action.data,
      }
    }

    case UPDATE_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.data,
      }
    }

    default:
      return state;

  }
}

export default NotificationsReducer;