import React from "react";
import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { notifications } from "../AppNotification/data";
import { RootState } from "../../appRedux/store";
import { onUpdateNotifications } from "../../appRedux/actions";
import { notificationInTitle } from "../../util/helpers";
import { Link } from "react-router-dom";
import { useBidiffySdkClient } from "../../util/customHooks";

const NotificationItem = ({ notification }: any) => {
  const { image, badge, name, time, message, user, developerId } = notification;
  const dispatch = useDispatch();
  const notifications = useSelector(({ notifications }: RootState) => notifications.notifications);
  const imageBaseUrl = process.env.REACT_APP_HH_API + '/upload/get/'
  const client = useBidiffySdkClient();

  const onRead = () => {
    const notif = notifications.filter((item: any) => item._id != notification.id);
    notificationInTitle(notif);
    dispatch(onUpdateNotifications(notif));
    client.updateNotification(notification.id, { ...notification, read: true })
  }

  return (

    <li className="gx-media">
        <div className="gx-user-thumb gx-mr-3">
          <Avatar className="gx-size-40"
            alt={"/assets/images/Profile.svg"}
            src={user?.media ? imageBaseUrl + user?.media : "/assets/images/Profile.svg"} />
          {badge > 0 ? <span className="gx-badge gx-badge-danger gx-text-white gx-rounded-circle">{badge}</span> : null}
        </div>
        <div className="gx-media-body">
          <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
            <h5 className="gx-text-capitalize gx-user-name gx-mb-0"><span className="gx-link">{name}</span></h5>
            <span className="gx-meta-date"><small>{time}</small></span>
          </div>
          <p className="gx-fs-sm" dangerouslySetInnerHTML={{ __html: message }} />
          {/*<span className="gx-btn gx-btn-sm gx-top2 gx-text-muted" style={{ display: 'none' }}><i className="icon icon-reply gx-pr-2" />Reply</span>*/}
          <Link to={notification.user? ("/developers/profile?_key=" + developerId) : ("/companies-messages")}>
          <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted" >
              <i className="icon icon-reply gx-pr-2" />{notification.user ? "View profile" : "View message"}</span>
          </Link>
          <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted" onClick={onRead} >
            <i className="icon icon-custom-view gx-pr-2" />Read</span>
        </div>
    </li>
  );
};

export default NotificationItem;
