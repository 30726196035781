import AuthService from './services/auth'
import UserService from './services/user'
import TechnologyService from './services/technology'
import EngineerService from './services/engineerTypes'
import ContratService from './services/contrat'
import ProjectService from './services/project'
import MissionService from './services/mission'
import FinanceService from './services/finance'
import UploadService from './services/upload'
import CompanyService from './services/company'
import DeveloperService from './services/developer'
import StatsService from './services/stats'
import ProposalService from './services/proposal'
import ChoiceReasonService from './services/choiceReason'
import TestimonialService from './services/testimonial'
import ProcessService from './services/process'
import FaqService from './services/faq'
import TeamService from './services/team'
import AboutService from './services/about'
import CompanyMessageService from './services/companyMessage'
import TermService from './services/term'
import RecruitmentProcessService from './services/recruitmentProcess'
import RecruitmentProcessParameterService from './services/recruitmentProcessParameter'
import TextMessageService from './services/textMessage'
import GlobalService from './services/global'
import OfferService from './services/offer'
import PatnerService from './services/patner'
import HeaderService from './services/header'

// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import API from './config'
import NotificationService from './services/notification'

const objectAssignDeep = require(`object-assign-deep`)

class BaseClient {
  options: dataSchemas.Options
  private authService!: AuthService
  private userService!: UserService
  private technologyService!: TechnologyService
  private engineerService!: EngineerService
  private contratService!: ContratService
  private projectService!: ProjectService
  private missionService!: MissionService
  private financeService!: FinanceService
  private uploadService!: UploadService
  private companyService!: CompanyService
  private developerService!: DeveloperService
  private statsService!: StatsService
  private proposalService!: ProposalService
  private choiceReasonService!: ChoiceReasonService
  private testimonialService!: TestimonialService
  private processService!: ProcessService
  private faqService!: FaqService
  private teamService!: TeamService
  private aboutService!: AboutService
  private companyMessageService!: CompanyMessageService
  private termService!: TermService
  private recruitmentProcessService!: RecruitmentProcessService
  private recruitmentProcessParameterService!: RecruitmentProcessParameterService
  private textMessageService!: TextMessageService
  private globalService!: GlobalService
  private offerService!: OfferService
  private patnerService!: PatnerService
  private headerService!: HeaderService
  private notificationService!: NotificationService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: dataSchemas.Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      accessToken: null,
      refreshToken: null,
      appLogoutCallback: null,
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: dataSchemas.Options) => {
    if (this.options.accessToken) {
      this.options.headers.Authorization = 'Bearer ' + this.options.accessToken;
    }
    this.options = objectAssignDeep(this.options, options)
    this.authService = new AuthService(this.options)
    this.userService = new UserService(this.options)
    this.technologyService = new TechnologyService(this.options)
    this.engineerService = new EngineerService(this.options)
    this.uploadService = new UploadService(this.options)
    this.contratService = new ContratService(this.options)
    this.projectService = new ProjectService(this.options)
    this.missionService = new MissionService(this.options)
    this.financeService = new FinanceService(this.options)
    this.companyService = new CompanyService(this.options)
    this.developerService = new DeveloperService(this.options)
    this.statsService = new StatsService(this.options)
    this.proposalService = new ProposalService(this.options)
    this.choiceReasonService = new ChoiceReasonService(this.options)
    this.testimonialService = new TestimonialService(this.options)
    this.processService = new ProcessService(this.options)
    this.faqService = new FaqService(this.options)
    this.teamService = new TeamService(this.options)
    this.aboutService = new AboutService(this.options)
    this.companyMessageService = new CompanyMessageService(this.options)
    this.termService = new TermService(this.options)
    this.recruitmentProcessService = new RecruitmentProcessService(this.options)
    this.recruitmentProcessParameterService = new RecruitmentProcessParameterService(this.options)
    this.textMessageService = new TextMessageService(this.options)
    this.globalService = new GlobalService(this.options)
    this.offerService = new OfferService(this.options)
    this.patnerService = new PatnerService(this.options)
    this.headerService = new HeaderService(this.options)
    this.notificationService = new NotificationService(this.options);
  }

  /**
   * User login
   * @param request LoginRequest. Login Credentials
   */
  login = (request: dataSchemas.LoginRequest) => this.authService.login(request)

  /**
   * Current User
   */
  me = () => this.authService.me()

  /**
   * Logout current User
   */
  logout = async () => this.authService.logout();


  getNewAccessToken = async (refreshToken: string) => this.authService.refresh(refreshToken);

  /**
   * Register new user
   * @param request . User informations
   */
  register = (request: any) =>
    this.authService.register(request)

  /**
   * Password recovery request, new password sent by email
   * @param request . Email
   */
  recoverPassword = (request: any) => this.authService.recoverPassword(request)

  /**
   * Password reset request
   * @param request . Email, old password and new password
   */
  resetPassword = (request: any) =>
    this.authService.resetPassword(request)

  /**
   * change email request
   * @param request . Email, old password and new password
   */
  changeEmail = (request: any) =>
    this.authService.changeEmail(request)

  /**
   * Get Users
   * @param request . JWT
   */
  getAllUsers = (
    typeAccount: string,
    paginationOptions = { skip: 0, limit: 100, search: '' }
  ) => this.userService.getAll(typeAccount, paginationOptions)

  /**
 * Get fiinance by id
 * @param id, JWT
 */
  getUserById = (id: string): Promise<any> => this.userService.getById(id)

  /**
   * Update Users
   * @param id, user data,  JWT
   */
  updateUser = (id: string, req: any) =>
    this.userService.update(id, req)

  /**
   * Delete Users
   * @param id, JWT
   */
  deleteUser = (id: string) => this.userService.delete(id)


  /**
* Check Available Email
* @param email
*/
  CheckAvailableEmail = (
    email: string,
  ) => this.userService.CheckAvailableEmail(email)


  // TECHNOLOGIES**********************************************************************************
  /**
   * Create a tecnology
   * @param request body
   */

  createTechnology = (
    body: dataSchemas.TechnologyRequest
  ): Promise<dataSchemas.TechnologyCreateResult> =>
    this.technologyService.createTechnology(body)

  /**
   * Get all technologies
   * @param paginationOption
   */
  getAllTechnologies = (
    paginationOption: dataSchemas.PaginationOptions = {
      offset: 0,
      limit: 10,
      search: ''
    }
  ): Promise<dataSchemas.TechnologyPagination> =>
    this.technologyService.getAllTechnologies(paginationOption)

  /**
   * Get all technologies with search param and pagination data
   * @param paginationOption
   */
  getSearchTechnologies = (
    paginationOption: dataSchemas.PaginationOptions = {
      offset: 0,
      limit: 10,
      search: ''
    }
  ): Promise<dataSchemas.TechnologyPagination> => this.technologyService.getAllTechnologies(paginationOption)

  /**
   * Get all technologies
   * @param request body
   */
  getSingleTechnology = (idTechnology: number) =>
    this.technologyService.getSingleTechnology(idTechnology)

  /**
   * Update a tecnology
   * @param request body
   */
  updateTechnology = (
    idTechnology: number,
    body: dataSchemas.TechnologyRequest
  ): Promise<dataSchemas.TechnologyCreateResult> =>
    this.technologyService.updateTechnology(idTechnology, body)

  /**
   * Delete a tecnology
   * @param request parameter idT>echnology
   */
  deleteTechnology = (idTechnology: number) =>
    this.technologyService.deleteTechnology(idTechnology)

  // CONTRAT*******************************************************


  /**
   * getAllContrats
   * @param request Request params
   */
  getAllContrats = (request: dataSchemas.ContractPagination) =>
    this.contratService.getAll(request)


  /**
   * Get contrat by id
   * @param id contrat id
   */
  getContratById = (id: string): Promise<any> => this.contratService.getById(id)

  /**
   * Create Contrat
   * @param body Contrat body
   */
  createContrat = (body: dataSchemas.Contrat): Promise<dataSchemas.Contrat> =>
    this.contratService.create(body)

  /**
   * Update contrat
   * @param id contrat id
   * @param body contrat body
   */
  updateContrat = (
    id: string,
    body: dataSchemas.Contrat
  ): Promise<dataSchemas.Contrat> => this.contratService.update(id, body)

  /**
   * Delete contrat
   * @param id contrat id
   */
  deleteContrat = async (id: string) => {
    return this.contratService
      .delete(id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a EngineerType.',
          error: e
        })
      })
  }

  // PROJECT*******************************************************

  /**
   * getAllProject
   * @param request Request params
   */
  getAllProjects = (request: dataSchemas.ProjectPagination): Promise<dataSchemas.ProjectPagination> =>
    this.projectService.getAll(request)

  /**
    * getAllProject
    * @param request Request params
    */
  getGeneralProjects = (request: dataSchemas.PaginationOptions): Promise<dataSchemas.ProjectPagination> =>
    this.projectService.getGeneral(request)

  /**
   * Get project by id
   * @param id project id
   */
  getProjectById = (id: string): Promise<any> => this.projectService.getById(id)

  /**
   * Create Project
   * @param body Project body
   */
  createProject = (body: dataSchemas.Project): Promise<dataSchemas.ProjectCreate> =>
    this.projectService.create(body)

  /**
   * Update project
   * @param id project id
   * @param body project body
   */
  updateProject = (
    id: string,
    body: dataSchemas.Project
  ): Promise<dataSchemas.ProjectCreate> => this.projectService.update(id, body)

  /**
   * Delete project
   * @param id project id
   */
  deleteProject = async (id: string) => {
    return this.projectService
      .delete(id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a EngineerType.',
          error: e
        })
      })
  }

  // MISSION*******************************************************

  /**
   * getAllMission
   * @param request Request params
   */
  getAllMissions = (request: dataSchemas.MissionPagination): Promise<dataSchemas.MissionPagination> => this.missionService.getAll(request)

  /**
   * Get mission by id
   * @param id mission id
   */
  getMissionById = (id: string): Promise<any> =>
    this.missionService.getById(id)

  /**
   * Create Mission
   * @param body Project body
   */
  createMission = (body: dataSchemas.Mission): Promise<dataSchemas.Mission> =>
    this.missionService.create(body)

  /**
   * Update mission
   * @param id mission id
   * @param body mission body
   */
  updateMission = (
    id: string,
    body: dataSchemas.Mission
  ): Promise<dataSchemas.Mission> =>
    this.missionService.update(id, body)

  /**
   * Delete mission
   * @param id mission id
   */
  deleteMission = async (id: string) => {
    return this.missionService
      .delete(id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a EngineerType.',
          error: e
        })
      })
  }
  // ENGINEER**********************************************************************************

  /**
   * getAllEnginneer
   * @param request Request params
   */
  getAllEngineerTypes = (request: dataSchemas.EngineerTypeRequest) =>
    this.engineerService.getAll(request)

  /**
   * Get engineerType by id
   * @param id EngineerType id
   */
  getEngineerTypeById = (id: number): Promise<dataSchemas.EngineerType> =>
    this.engineerService.getById(id)

  /**
   * Create EngineerType
   * @param body EngineerType body
   */
  createEngineerType = (
    body: dataSchemas.EngineerType
  ): Promise<dataSchemas.EngineerTypeCreateResult> => this.engineerService.create(body)

  /**
   * Update engineerType
   * @param id engineerType id
   * @param body engineerType body
   */
  updateEngineerType = (
    id: number,
    body: dataSchemas.EngineerType
  ): Promise<dataSchemas.EngineerTypeCreateResult> =>
    this.engineerService.update(id, body)

  /**
   * Delete engineerType
   * @param id EngineerType id
   */
  deleteEngineerType = async (id: number) => {
    return this.engineerService
      .delete(id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a EngineerType.',
          error: e
        })
      })
  }

  /**
   * Upload file
   * @param request File
   */
  uploadFile = (file: any) => this.uploadService.upload(file)

  /**
   * Get uploaded file
   * @param request filename String
   */
  getFile = (filename: string) => this.uploadService.get(filename)

  /**
   * Delete uploaded file
   * @param request filename String
   */
  deleteFile = (filename: string) => this.uploadService.delete(filename)

  /**
   * getAllFinances
   * @param request Request params
   */
  getAllFinances = (request: dataSchemas.FinancePagination) =>
    this.financeService.getAll(request)

  /**
   * Get fiinance by id
   * @param id finance id
   */
  getFinanceById = (id: string): Promise<any> => this.financeService.getById(id)

  /**
   * Create Finance
   * @param body Project body
   */
  createFinance = (body: dataSchemas.Finance): Promise<dataSchemas.Finance> =>
    this.financeService.create(body)

  /**
   * Update finance
   * @param id finance id
   * @param body finance body
   */
  updateFinance = (
    id: string,
    body: dataSchemas.Finance
  ): Promise<dataSchemas.Finance> => this.financeService.update(id, body)

  /**
   * delete finance
   * @param id finance id
   */
  deleteFinance = (id: string): Promise<dataSchemas.Finance> =>
    this.financeService.delete(id)


  // Company **********************************************************************************

  /**
  * getAllEnginneer
  * @param request Request params
  */
  getAllCompany = (request: dataSchemas.CompanyPaginationOption) => this.companyService.getAll(request)

  /**
  * Get Company by id
  * @param id Company id
  */
  getCompanyById = (id: number): Promise<dataSchemas.Company> =>
    this.companyService.getById(id)

  /**
   * Create Company
   * @param body Company body
   */
  createCompany = (
    body: dataSchemas.Company
  ): Promise<dataSchemas.Company> => this.companyService.create(body)

  /**
   * Update Company
   * @param id Company id
   * @param body Company body
   */
  updateCompany = (
    id: string,
    body: dataSchemas.Company
  ): Promise<dataSchemas.Company> =>
    this.companyService.update(id, body)


  /**
   * Delete Company
   * @param id Company id
   */
  deleteCompany = async (id: string) => {
    return this.companyService
      .delete(id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a Company.',
          error: e
        })
      })
  }

  // Developer **********************************************************************************

  /**
   * getAllDevelopers
   * @param request Request params
   */
  getAllDevelopers = (
    request: any,
    paginationOption: dataSchemas.PaginationOptions
  ) => this.developerService.getAll(request, paginationOption)



  /**
 * getAllGeneralDevelopers
 * @param request Request params
 */
  getAllGeneralDevelopers = (
    request: any,
    paginationOption: dataSchemas.PaginationOptions
  ) => this.developerService.getAllGeneral(request, paginationOption)



  /**
 * getAllAvailableByMissionDate
 * @param request Request params
 */
  getAllAvailableByMissionDate = (
    request: any,
    paginationOption: dataSchemas.PaginationWithPeridoOptions
  ) => this.developerService.getAllAvailableByMissionDate(request, paginationOption)
  /**
 * getAllAvailableByMissionDate
 * @param request Request params
 */
  getAllAvailableByContratDate = (
    request: any,
    paginationOption: dataSchemas.PaginationWithPeridoOptions
  ) => this.developerService.getAllAvailableByContratDate(request, paginationOption)

  /**
* getAllGeneralByCertified
* @param request Request params
*/
  getAllGeneralByCertified = (
    request: any,
    paginationOption: dataSchemas.PaginationByCertified
  ) => this.developerService.getAllGeneralByCertified(request, paginationOption)


  /**
* getAllGeneralByCountry
* @param request Request params
*/
  getAllGeneralByCountry = (
    request: any,
    paginationOption: dataSchemas.PaginationByCountry
  ) => this.developerService.getAllGeneralByCountry(request, paginationOption)


  /**
   * Get Developer by id
   * @param id Developer id
   */
  getDeveloperById = (id: string): Promise<dataSchemas.Developer> =>
    this.developerService.getById(id)

  /**
 * Get a developers missions and projects list  by id
 * @param id Developer id
 */
  getDevMissionAndProjects = (id: string): Promise<dataSchemas.Developer> =>
    this.developerService.getDevMissionAndProjects(id)

  /**
   * Get Developer by user id
   * @param id User id
   */
  getDeveloperByUserId = (id: string): Promise<dataSchemas.Developer> =>
    this.developerService.getByUserId(id)

  /**
   * Create Developer
   * @param body Developer body
   */
  createDeveloper = (
    body: dataSchemas.Developer,
  ): Promise<dataSchemas.Developer> => this.developerService.create(body)

  /**
   * Update Developer
   * @param id Developer id
   * @param body Developer body
   */
  updateDeveloper = (
    id: string,
    body: dataSchemas.Developer,
  ): Promise<dataSchemas.Developer> =>
    this.developerService.update(id, body)

  /**
   * Delete Developer
   * @param id Developer id
   */
  deleteDeveloper = async (id: string) =>
    this.developerService.delete(id)


  /**
 * Get all certified Developer with excluded users
 * @param body certified Developer with excluded users
 */
  getAllWithExcludeUsers = (
    body: dataSchemas.PaginationWithExcludedUsers,
  ): Promise<dataSchemas.Developer> => this.developerService.getAllWithExcludeUsers(body)

  /**
* Get all developers by technologies
* @param body certified Developer with excluded users
*/
  getAllGeneralByTechnology = (
    body: dataSchemas.PaginationWithTechnologies,
  ) => this.developerService.getAllGeneralByTechnology(body)


  /**
* Get all developers by queries
* @param body certified Developer with excluded users
*/
  getAllGeneralByQuery = (
    body: dataSchemas.PaginationWithQuery,
  ) => this.developerService.getAllGeneralByQuery(body)



  // Stats **********************************************************************************
  /**
   * Get developer count
   */
  getDevelopersCount = async () =>
    this.statsService.getDevelopersCount()

  /**
   * Get new developers count
   */
  getNewDevelopersCount = async () =>
    this.statsService.getNewDevelopersCount()

  /**
   * Get active developers count
   */
  getActiveDevelopersCount = async () =>
    this.statsService.getActiveDevelopersCount()

  /**
   * Get projects count
   */
  getProjectsCount = async () =>
    this.statsService.getProjectsCount()

  /**
   * Get missions count
   */
  getMissionsCount = async () =>
    this.statsService.getMissionsCount()

  /**
   * Get companies count
   */
  getCompaniesCount = async () =>
    this.statsService.getCompaniesCount()

  /**
   * Get total revenues
   */
  getTotalRevenues = async () =>
    this.statsService.getTotalRevenues()

  /**
   * Get total expenses
   */
  getTotalExpenses = async () =>
    this.statsService.getTotalExpenses()

  /**
   * Get best developers
   * @param n number of items to get
   */
  getBestDevelopers = async (n: number) =>
    this.statsService.getBestDevelopers(n)

  /**
   * Get best projects
   * @param n number of items to get
   */
  getBestProjects = async (n: number) =>
    this.statsService.getBestProjects(n)

  /**
   * Get best companies
   * @param n number of items to get
   */
  getBestCompanies = async (n: number) =>
    this.statsService.getBestCompanies(n)

  /**
   * Get history
   * @param from Date
   * @param to Date
   */
  getHistory = async (from: Date, to: Date) =>
    this.statsService.history(from, to)

  /*******************  Proposal ************************************* */

  /**
  * getAllProposals
  * @param request Request params
  */
  getAllProposals = (request: dataSchemas.SampleRequest) =>
    this.proposalService.getAll(request)

  /**
   * Get fiinance by id
   * @param id proposal id
   */
  getProposalById = (id: string): Promise<any> => this.proposalService.getById(id)

  /**
   * Create Proposal
   * @param body Project body
   */
  createProposal = (body: dataSchemas.Proposal): Promise<dataSchemas.Proposal> =>
    this.proposalService.create(body)

  /**
   * Update proposal
   * @param id proposal id
   * @param body proposal body
   */
  updateProposal = (
    id: string,
    body: dataSchemas.Proposal
  ): Promise<dataSchemas.Proposal> => this.proposalService.update(id, body)

  /**
   * delete proposal
   * @param id proposal id
   */
  deleteProposal = (id: string): Promise<dataSchemas.Proposal> =>
    this.proposalService.delete(id)

  /*******************  ChoiceReason ************************************* */

  /**
  * getAllChoiceReasons
  * @param request Request params
  */
  getAllChoiceReasons = (request: dataSchemas.SampleRequest) =>
    this.choiceReasonService.getAll(request)

  /**
   * Get fiinance by id
   * @param id choiceReason id
   */
  getChoiceReasonById = (id: string): Promise<any> => this.choiceReasonService.getById(id)

  /**
   * Create ChoiceReason
   * @param body Project body
   */
  createChoiceReason = (body: dataSchemas.ChoiceReason): Promise<dataSchemas.ChoiceReason> =>
    this.choiceReasonService.create(body)

  /**
   * Update choiceReason
   * @param id choiceReason id
   * @param body choiceReason body
   */
  updateChoiceReason = (
    id: string,
    body: dataSchemas.ChoiceReason
  ): Promise<dataSchemas.ChoiceReason> => this.choiceReasonService.update(id, body)

  /**
   * delete choiceReason
   * @param id choiceReason id
   */
  deleteChoiceReason = (id: string): Promise<dataSchemas.ChoiceReason> =>
    this.choiceReasonService.delete(id)

  /*******************  Testimonial ************************************* */

  /**
  * getAllTestimonials
  * @param request Request params
  */
  getAllTestimonials = (request: dataSchemas.SampleRequest) =>
    this.testimonialService.getAll(request)

  /**
   * Get fiinance by id
   * @param id testimonial id
   */
  getTestimonialById = (id: string): Promise<any> => this.testimonialService.getById(id)

  /**
   * Create Testimonial
   * @param body Project body
   */
  createTestimonial = (body: dataSchemas.Testimonial): Promise<dataSchemas.Testimonial> =>
    this.testimonialService.create(body)

  /**
   * Update testimonial
   * @param id testimonial id
   * @param body testimonial body
   */
  updateTestimonial = (
    id: string,
    body: dataSchemas.Testimonial
  ): Promise<dataSchemas.Testimonial> => this.testimonialService.update(id, body)

  /**
   * delete testimonial
   * @param id testimonial id
   */
  deleteTestimonial = (id: string): Promise<dataSchemas.Testimonial> =>
    this.testimonialService.delete(id)

  /*******************  Process ************************************* */

  /**
  * getAllProcesss
  * @param request Request params
  */
  getAllProcesss = (request: dataSchemas.SampleRequest) =>
    this.processService.getAll(request)

  /**
   * Get fiinance by id
   * @param id process id
   */
  getProcessById = (id: string): Promise<any> => this.processService.getById(id)

  /**
   * Create Process
   * @param body Project body
   */
  createProcess = (body: dataSchemas.Process): Promise<dataSchemas.Process> =>
    this.processService.create(body)

  /**
   * Update process
   * @param id process id
   * @param body process body
   */
  updateProcess = (
    id: string,
    body: dataSchemas.Process
  ): Promise<dataSchemas.Process> => this.processService.update(id, body)

  /**
   * delete process
   * @param id process id
   */
  deleteProcess = (id: string): Promise<dataSchemas.Process> =>
    this.processService.delete(id)

  /*******************  Faq ************************************* */

  /**
  * getAllFaqs
  * @param request Request params
  */
  getAllFaqs = (request: dataSchemas.SampleRequest) =>
    this.faqService.getAll(request)

  /**
   * Get fiinance by id
   * @param id faq id
   */
  getFaqById = (id: string): Promise<any> => this.faqService.getById(id)

  /**
   * Create Faq
   * @param body Project body
   */
  createFaq = (body: dataSchemas.Faq): Promise<dataSchemas.Faq> =>
    this.faqService.create(body)

  /**
   * Update faq
   * @param id faq id
   * @param body faq body
   */
  updateFaq = (
    id: string,
    body: dataSchemas.Faq
  ): Promise<dataSchemas.Faq> => this.faqService.update(id, body)

  /**
   * delete faq
   * @param id faq id
   */
  deleteFaq = (id: string): Promise<dataSchemas.Faq> =>
    this.faqService.delete(id)


  /*******************  Team ************************************* */

  /**
  * getAllTeams
  * @param request Request params
  */
  getAllTeams = (request: dataSchemas.SampleRequest) =>
    this.teamService.getAll(request)

  /**
   * Get team by id
   * @param id team id
   */
  getTeamById = (id: string): Promise<any> => this.teamService.getById(id)

  /**
   * Create Team
   * @param body Project body
   */
  createTeam = (body: dataSchemas.Team): Promise<dataSchemas.Team> =>
    this.teamService.create(body)

  /**
   * Update team
   * @param id team id
   * @param body team body
   */
  updateTeam = (
    id: string,
    body: dataSchemas.Team
  ): Promise<dataSchemas.Team> => this.teamService.update(id, body)

  /**
   * delete team
   * @param id team id
   */
  deleteTeam = (id: string): Promise<dataSchemas.Team> =>
    this.teamService.delete(id)

  /*******************  About ************************************* */

  /**
  * getAllAbouts
  * @param request Request params
  */
  getAllAbouts = (request: dataSchemas.SampleRequest) =>
    this.aboutService.getAll(request)

  /**
   * Get about by id
   * @param id about id
   */
  getAboutById = (id: string): Promise<any> => this.aboutService.getById(id)

  /**
   * Create About
   * @param body Project body
   */
  createAbout = (body: dataSchemas.About): Promise<dataSchemas.About> =>
    this.aboutService.create(body)

  /**
   * Update about
   * @param id about id
   * @param body about body
   */
  updateAbout = (
    id: string,
    body: dataSchemas.About
  ): Promise<dataSchemas.About> => this.aboutService.update(id, body)

  /**
   * delete about
   * @param id about id
   */
  deleteAbout = (id: string): Promise<dataSchemas.About> =>
    this.aboutService.delete(id)

  /*******************  CompanyMessage ************************************* */

  /**
  * getAllCompanyMessages
  * @param request Request params
  */
  getAllCompanyMessages = (request: dataSchemas.SampleRequest) =>
    this.companyMessageService.getAll(request)

  /**
   * Get companyMessage by id
   * @param id companyMessage id
   */
  getCompanyMessageById = (id: string): Promise<any> => this.companyMessageService.getById(id)

  /**
   * Create CompanyMessage
   * @param body Project body
   */
  createCompanyMessage = (body: any): Promise<any> =>
    this.companyMessageService.create(body)

  /**
   * Update companyMessage
   * @param id companyMessage id
   * @param body companyMessage body
   */
  updateCompanyMessage = (
    id: string,
    body: any
  ): Promise<any> => this.companyMessageService.update(id, body)

  /**
   * delete companyMessage
   * @param id companyMessage id
   */
  deleteCompanyMessage = (id: string): Promise<any> =>
    this.companyMessageService.delete(id)
  /*******************  Term ************************************* */

  /**
  * getAllTerms
  * @param request Request params
  */
  getAllTerms = (request: dataSchemas.SampleRequest) =>
    this.termService.getAll(request)

  /**
   * Get fiinance by id
   * @param id term id
   */
  getTermById = (id: string): Promise<any> => this.termService.getById(id)

  /**
   * Create Term
   * @param body Project body
   */
  createTerm = (body: dataSchemas.Term): Promise<dataSchemas.Term> =>
    this.termService.create(body)

  /**
   * Update term
   * @param id term id
   * @param body term body
   */
  updateTerm = (
    id: string,
    body: dataSchemas.Term
  ): Promise<dataSchemas.Term> => this.termService.update(id, body)

  /**
   * delete term
   * @param id term id
   */
  deleteTerm = (id: string): Promise<dataSchemas.Term> =>
    this.termService.delete(id)


  /*******************  Header ************************************* */

  /**
  * getAllHeaders
  * @param request Request params
  */
  getAllHeaders = (request: dataSchemas.SampleRequest) =>
    this.headerService.getAll(request)

  /**
   * Get fiinance by id
   * @param id header id
   */
  getHeaderById = (id: string): Promise<any> => this.headerService.getById(id)

  /**
   * Create Header
   * @param body Project body
   */
  createHeader = (body: dataSchemas.Header): Promise<dataSchemas.Header> =>
    this.headerService.create(body)

  /**
   * Update header
   * @param id header id
   * @param body header body
   */
  updateHeader = (
    id: string,
    body: dataSchemas.Header
  ): Promise<dataSchemas.Header> => this.headerService.update(id, body)

  /**
   * delete header
   * @param id header id
   */
  deleteHeader = (id: string): Promise<dataSchemas.Header> =>
    this.headerService.delete(id)


  /*******************  RecruitmentProcess ************************************* */

  /**
  * getAllRecruitmentProcesss
  * @param request Request params
  */
  getAllRecruitmentProcesss = (request: dataSchemas.SampleRequest) =>
    this.recruitmentProcessService.getAll(request)

  /**
   * Get fiinance by id
   * @param id recruitmentProcess id
   */
  getRecruitmentProcessById = (id: string): Promise<any> => this.recruitmentProcessService.getById(id)

  /**
   * Create RecruitmentProcess
   * @param body Project body
   */
  createRecruitmentProcess = (body: dataSchemas.RecruitmentProcess): Promise<dataSchemas.RecruitmentProcess> =>
    this.recruitmentProcessService.create(body)

  /**
   * Update recruitmentProcessStep1
   * @param id recruitmentProcess id
   * @param body recruitmentProcess body
   */
  updateRecruitmentProcess = (
    id: string,
    body: dataSchemas.RecruitmentProcess
  ): Promise<dataSchemas.RecruitmentProcess> => this.recruitmentProcessService.update(id, body)


  /**
  * Update recruitmentProcessStep2
  * @param id recruitmentProcess id
  * @param body recruitmentProcess body
  */
  updateRecruitmentProcessStep1 = (
    id: string,
    body: any
  ): Promise<dataSchemas.RecruitmentProcess> => this.recruitmentProcessService.updateStep1(id, body)


  /**
 * Update recruitmentProcess
 * @param id recruitmentProcess id
 * @param body recruitmentProcess body
 */
  updateRecruitmentProcessStep2 = (
    id: string,
    body: any
  ): Promise<dataSchemas.RecruitmentProcess> => this.recruitmentProcessService.updateStep2(id, body)


  /**
   * Update recruitmentProcessStep3
   * @param id recruitmentProcess id
   * @param body recruitmentProcess body
   */
  updateRecruitmentProcessStep3 = (
    id: string,
    body: any
  ): Promise<dataSchemas.RecruitmentProcess> => this.recruitmentProcessService.updateStep3(id, body)


  /**
 * Update recruitmentProcessStep4
 * @param id recruitmentProcess id
 * @param body recruitmentProcess body
 */
  updateRecruitmentProcessStep4 = (
    id: string,
    body: any
  ): Promise<dataSchemas.RecruitmentProcess> => this.recruitmentProcessService.updateStep4(id, body)






  /**
   * delete recruitmentProcess
   * @param id recruitmentProcess id
   */
  deleteRecruitmentProcess = (id: string): Promise<dataSchemas.RecruitmentProcess> =>
    this.recruitmentProcessService.delete(id)

  /*******************  RecruitmentProcessParameter ************************************* */

  /**
  * getAllRecruitmentProcessParameters
  * @param request Request params
  */
  getAllRecruitmentProcessParameters = (request: dataSchemas.SampleRequest) =>
    this.recruitmentProcessParameterService.getAll(request)

  /**
   * Get fiinance by id
   * @param id recruitmentProcessParameter id
   */
  getRecruitmentProcessParameterById = (id: string): Promise<any> => this.recruitmentProcessParameterService.getById(id)

  /**
   * Create RecruitmentProcessParameter
   * @param body Project body
   */
  createRecruitmentProcessParameter = (body: dataSchemas.RecruitmentProcessParameter): Promise<dataSchemas.RecruitmentProcessParameter> =>
    this.recruitmentProcessParameterService.create(body)

  /**
   * Update recruitmentProcessParameter
   * @param id recruitmentProcessParameter id
   * @param body recruitmentProcessParameter body
   */
  updateRecruitmentProcessParameter = (
    id: string,
    body: dataSchemas.RecruitmentProcessParameter
  ): Promise<dataSchemas.RecruitmentProcessParameter> => this.recruitmentProcessParameterService.update(id, body)

  /**
   * delete recruitmentProcessParameter
   * @param id recruitmentProcessParameter id
   */
  deleteRecruitmentProcessParameter = (id: string): Promise<dataSchemas.RecruitmentProcessParameter> =>
    this.recruitmentProcessParameterService.delete(id)

  /*******************  TextMessage ************************************* */

  /**
  * getAllTextMessages
  * @param request Request params
  */
  getAllTextMessages = (request: dataSchemas.SampleRequest) =>
    this.textMessageService.getAll(request)

  /**
   * Get fiinance by id
   * @param id textMessage id
   */
  getTextMessageById = (id: string): Promise<any> => this.textMessageService.getById(id)

  /**
   * Create TextMessage
   * @param body Project body
   */
  createTextMessage = (body: dataSchemas.TextMessage): Promise<dataSchemas.TextMessage> =>
    this.textMessageService.create(body)

  /**
   * Update textMessage
   * @param id textMessage id
   * @param body textMessage body
   */
  updateTextMessage = (
    id: string,
    body: dataSchemas.TextMessage
  ): Promise<dataSchemas.TextMessage> => this.textMessageService.update(id, body)

  /**
   * delete textMessage
   * @param id textMessage id
   */
  deleteTextMessage = (id: string): Promise<dataSchemas.TextMessage> =>
    this.textMessageService.delete(id)

  /*******************  Offer ************************************* */

  /**
  * getAllOffers
  * @param request Request params
  */
  getAllOffers = (request: dataSchemas.SampleRequest) =>
    this.offerService.getAll(request)

  /**
   * Get offer by id
   * @param id offer id
   */
  getOfferById = (id: string): Promise<any> => this.offerService.getById(id)

  /**
   * Create Offer
   * @param body Project body
   */
  createOffer = (body: dataSchemas.Offer): Promise<dataSchemas.Offer> =>
    this.offerService.create(body)

  /**
   * Update offer
   * @param id offer id
   * @param body offer body
   */
  updateOffer = (
    id: string,
    body: dataSchemas.Offer
  ): Promise<dataSchemas.Offer> => this.offerService.update(id, body)

  /**
   * delete offer
   * @param id offer id
   */
  deleteOffer = (id: string): Promise<dataSchemas.Offer> =>
    this.offerService.delete(id)



  /*******************  Patner ************************************* */

  /**
  * getAllPatners
  * @param request Request params
  */
  getAllPatners = (request: dataSchemas.SampleRequest) =>
    this.patnerService.getAll(request)

  /**
   * Get patner by id
   * @param id patner id
   */
  getPatnerById = (id: string): Promise<any> => this.patnerService.getById(id)

  /**
   * Create Patner
   * @param body Patner body
   */
  createPatner = (body: dataSchemas.Patner): Promise<dataSchemas.Patner> =>
    this.patnerService.create(body)

  /**
   * Update patner
   * @param id patner id
   * @param body patner body
   */
  updatePatner = (
    id: string,
    body: dataSchemas.Patner
  ): Promise<dataSchemas.Patner> => this.patnerService.update(id, body)

  /**
   * delete patner
   * @param id patner id
   */
  deletePatner = (id: string): Promise<dataSchemas.Patner> =>
    this.patnerService.delete(id)


  /*******************  Global  ************************************* */


  /**
   * Create TextMessage
   * @param body Project body
   */
  sendMailToAdmin = (body: dataSchemas.MailToAdmin): Promise<dataSchemas.TextMessage> =>
    this.globalService.sendMailToAdmin(body)


  /**
   * Send account activation email
   * @param email user email
   */
  sendAccountActivationEmail = (email: string): Promise<any> =>
    this.authService.sendAccountActivationEmail(email)


  getNotifications = (paginationOptions: any): Promise<any> =>
    this.notificationService.getAll(paginationOptions)

  createNotification = (data: any) => this.notificationService.create(data);

  updateNotification = (id: string, req: any) => this.notificationService.update(id, req);

  deleteNotification = (id: string) => this.notificationService.delete(id);

}

export * as types from './utils/DataSchemas'

// wrap the client class so that ...
const getNewAccessTokenIfFailedOnceWrapper = (f: Function, instance: BaseClient) => {
  return async (...args: any[]) => {
    // if no access token, we request an access token and reconfigure the client
    if (instance?.options?.accessToken == null && instance?.options?.refreshToken != null) {
      try {
        const response = await instance.getNewAccessToken(instance.options.refreshToken);
        instance.options.accessToken = response.access_token;
        instance.configure(instance.options);
      } catch (err) {
        if (instance.options.appLogoutCallback != null) {
          instance.options.appLogoutCallback()
        }
        console.warn(err);
      }
    }
    // if the first call fails, we requests an access token and make a second call
    // if the second call fail, appLogoutCallBack is called if provided
    // error is sent to client in second case
    return f(...args).then((response: any) => {
      return response;
    }).catch((originalError: any) => {
      if (originalError.statusCode === 401 && instance.options.refreshToken) {
        return instance.getNewAccessToken(instance.options.refreshToken).then((response: any) => {
          instance.options.accessToken = response.access_token;
          instance.configure(instance.options)
          return f(...args).then((response: any) => response).catch((error: any) => {
            if (error.statusCode === 401) {
              if (instance.options.appLogoutCallback != null) {
                instance.options.appLogoutCallback()
              }
              throw error;
            } else {
              throw error;
            }
          })
        }).catch(() => { throw originalError })
      }
      throw originalError;
    })
  }
}

const loginWrapper = (f: Function, instance: BaseClient) => {
  return async (...args: any[]) => {
    return f(...args).then((response: any) => {
      instance.options.accessToken = response.access_token;
      instance.options.refreshToken = response.refresh_token;
      instance.configure(instance.options)
      return { refresh_token: response.refresh_token }
    }).catch((error: any) => { throw error });
  }
}

const getClient = function (baseClient: any) {
  const instance = new baseClient()
  instance.configure()
  instance.login = loginWrapper(instance.login, instance);
  const notWrap = ['login', 'getNewAccessToken', 'configure'];
  for (const prop in instance) {
    if (typeof instance[prop] === 'function' && !notWrap.includes(prop)) {
      instance[prop] = getNewAccessTokenIfFailedOnceWrapper(instance[prop], instance)
    }
  }
  return instance;
}

export const client: BaseClient = getClient(BaseClient)