import {
    GET_ALL_TEXTMESSAGE,
    ADD_TEXTMESSAGE,
    UPDATE_TEXTMESSAGE,
    DELETE_TEXTMESSAGE
} from "../../constants/ActionTypes";

import { TextMessage} from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    textMessagesData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const TextMessageListFormater = (data: TextMessage[]) => {
    const results = [{}]
    results.pop()
    data.map((textMessageData: any) => {
        results.push(TextMessageFormat(textMessageData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value+'';
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }




export const TextMessageFormat = (textMessageData: TextMessage) => {
    return {
        key: textMessageData._id,
        title: textMessageData.title||'',
        description : epurator(textMessageData.description),

    }
}

const TextMessageReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_TEXTMESSAGE: {
            return {
                ...state,
                textMessagesData: TextMessageListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_TEXTMESSAGE:
            const project = TextMessageFormat(action.data);
            return {
                ...state,
                textMessageData: updatedArray(state.textMessagesData, project),
                showModal: false
            };

        case DELETE_TEXTMESSAGE:
            const _TEXTMESSAGE = action.data;
            return {
                ...state,
                textMessageData: arrayReduce(state.textMessagesData, _TEXTMESSAGE),
                total: state.total-1,
                showModal: false
            };

        case ADD_TEXTMESSAGE:
            let textMessageData = !state.textMessagesData ? [] : state.textMessagesData;
            
            textMessageData.unshift(TextMessageFormat(action.data));
            return {
                ...state,
                textMessageData: textMessageData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default TextMessageReducer;