import {
    GET_ALL_RECRUITMENTPROCESS,
    ADD_RECRUITMENTPROCESS,
    UPDATE_RECRUITMENTPROCESS,
    DELETE_RECRUITMENTPROCESS
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllRecruitmentProcess = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllRecruitmentProcesss(data).then((response) => {
            
            dispatch({
                type: GET_ALL_RECRUITMENTPROCESS,
                result: response.recruitmentProcesss,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddRecruitmentProcess = (recruitmentProcess: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_RECRUITMENTPROCESS,
            data: recruitmentProcess
        });
    }
};


export const onUpdateRecruitmentProcess = (recruitmentProcess: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_RECRUITMENTPROCESS,
                data: recruitmentProcess
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteRecruitmentProcess = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_RECRUITMENTPROCESS,
            data: data
        });
    };
};


