// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const SWITCH_CURRENCY = 'SWITCH-CURRENCY';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const UPDATE_CURRENCY_RATES = 'UPDATE_CURRENCY_RATES';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

//Engineer Types
export const GET_ALL_ENGINEER_TYPES = 'GET_ALL_ENGINEER_TYPES';
export const ADD_ENGINEER_TYPES = 'ADD_ENGINEER_TYPES';
export const UPDATE_ENGINEER_TYPES = 'UPDATE_ENGINEER_TYPES';
export const DELETE_ENGINEER_TYPES = 'DELETE_ENGINEER_TYPES';
export const SHOW_MODAL = 'SHOW_MODAL';
export const FILTER = 'FILTER';

//PROJECTS
export const GET_ALL_PROJECT = 'GET_ALL_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

//COMPANYS
export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

//ADMINS
export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const ADD_ADMIN = 'ADD_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';

//FINANCES
export const GET_ALL_FINANCE = 'GET_ALL_FINANCE';
export const ADD_FINANCE = 'ADD_FINANCE';
export const UPDATE_FINANCE = 'UPDATE_FINANCE';
export const DELETE_FINANCE = 'DELETE_FINANCE';


//Technologies 

export const GET_TECHNOLOGIES = "GET_TECHNOLOGIES"
export const SELECT_ONE = "SELECT_ONE"


//Contrats 

export const GET_CONTRATS = "GET_CONTRATS"
export const GET_ONE_CONTRAT = "GET_ONE_CONTRAT "

//Missions 

export const GET_MISSION = "GET_MISSION"
export const GET_ONE_MISSION = "GET_ONE_MISSION"

/// WEBSITE MODULE

//PROPOSALS
export const GET_ALL_PROPOSAL = 'GET_ALL_PROPOSAL';
export const ADD_PROPOSAL = 'ADD_PROPOSAL';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';

//CHOICEREASONS
export const GET_ALL_CHOICEREASON = 'GET_ALL_CHOICEREASON';
export const ADD_CHOICEREASON = 'ADD_CHOICEREASON';
export const UPDATE_CHOICEREASON = 'UPDATE_CHOICEREASON';
export const DELETE_CHOICEREASON = 'DELETE_CHOICEREASON';

//TESTIMONIALS
export const GET_ALL_TESTIMONIAL = 'GET_ALL_TESTIMONIAL';
export const ADD_TESTIMONIAL = 'ADD_TESTIMONIAL';
export const UPDATE_TESTIMONIAL = 'UPDATE_TESTIMONIAL';
export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL';

//PROCESSS
export const GET_ALL_PROCESS = 'GET_ALL_PROCESS';
export const ADD_PROCESS = 'ADD_PROCESS';
export const UPDATE_PROCESS = 'UPDATE_PROCESS';
export const DELETE_PROCESS = 'DELETE_PROCESS';

//FAQS
export const GET_ALL_FAQ = 'GET_ALL_FAQ';
export const ADD_FAQ = 'ADD_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';

//TEAMS
export const GET_ALL_TEAM = 'GET_ALL_TEAM';
export const ADD_TEAM = 'ADD_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';

//ABOUTS
export const GET_ALL_ABOUT = 'GET_ALL_ABOUT';
export const ADD_ABOUT = 'ADD_ABOUT';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const DELETE_ABOUT = 'DELETE_ABOUT';

//TERMS
export const GET_ALL_TERM = 'GET_ALL_TERM';
export const ADD_TERM = 'ADD_TERM';
export const UPDATE_TERM = 'UPDATE_TERM';
export const DELETE_TERM = 'DELETE_TERM';

//RECRUITMENTPROCESSS
export const GET_ALL_RECRUITMENTPROCESS = 'GET_ALL_RECRUITMENTPROCESS';
export const ADD_RECRUITMENTPROCESS = 'ADD_RECRUITMENTPROCESS';
export const UPDATE_RECRUITMENTPROCESS = 'UPDATE_RECRUITMENTPROCESS';
export const DELETE_RECRUITMENTPROCESS = 'DELETE_RECRUITMENTPROCESS';



//RECRUITMENTPROCESSPARAMETERS
export const GET_ALL_RECRUITMENTPROCESSPARAMETER = 'GET_ALL_RECRUITMENTPROCESSPARAMETER';
export const ADD_RECRUITMENTPROCESSPARAMETER = 'ADD_RECRUITMENTPROCESSPARAMETER';
export const UPDATE_RECRUITMENTPROCESSPARAMETER = 'UPDATE_RECRUITMENTPROCESSPARAMETER';
export const DELETE_RECRUITMENTPROCESSPARAMETER = 'DELETE_RECRUITMENTPROCESSPARAMETER';

//TEXTMESSAGES
export const GET_ALL_TEXTMESSAGE = 'GET_ALL_TEXTMESSAGE';
export const ADD_TEXTMESSAGE = 'ADD_TEXTMESSAGE';
export const UPDATE_TEXTMESSAGE = 'UPDATE_TEXTMESSAGE';
export const DELETE_TEXTMESSAGE = 'DELETE_TEXTMESSAGE';



//OFFERS
export const GET_ALL_OFFER = 'GET_ALL_OFFER';
export const ADD_OFFER = 'ADD_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const DELETE_OFFER = 'DELETE_OFFER';

//PATNERS
export const GET_ALL_PATNER = 'GET_ALL_PATNER';
export const ADD_PATNER = 'ADD_PATNER';
export const UPDATE_PATNER = 'UPDATE_PATNER';
export const DELETE_PATNER = 'DELETE_PATNER';

//HEADERS
export const GET_ALL_HEADER = 'GET_ALL_HEADER';
export const ADD_HEADER = 'ADD_HEADER';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const DELETE_HEADER = 'DELETE_HEADER';

// NOTIFICATIONS
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const SET_SOCKET = 'SET_SOCKET';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';