import {
    GET_ALL_TESTIMONIAL,
    ADD_TESTIMONIAL,
    UPDATE_TESTIMONIAL,
    DELETE_TESTIMONIAL
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllTestimonial = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllTestimonials(data).then((response) => {
           
            dispatch({
                type: GET_ALL_TESTIMONIAL,
                result: response.testimonials,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddTestimonial = (testimonial: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_TESTIMONIAL,
            data: testimonial
        });
    }
};


export const onUpdateTestimonial = (testimonial: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_TESTIMONIAL,
                data: testimonial
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteTestimonial = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_TESTIMONIAL,
            data: data
        });
    };
};


