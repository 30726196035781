import React from "react";

const CircularProgress = ({className}) => <div className={`${className}`}>
 {/* <img src="/assets/images/loader.svg" alt="loader"/>*/}
 <div className="loading-section">
    <img className="loading-logo" src="/assets/images/loader1.png" />
    <div className="loading-icon"></div>
  </div>
</div>;
export default CircularProgress;
  