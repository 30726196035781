import createSagaMiddleware from "redux-saga";
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk,sagaMiddleware, routeMiddleware];
const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, createRootReducer(history))


/*export default function configureStore(preloadedState = {}) {
  const store = createStore(
    persistedReducer,//createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}*/


export const store = createStore(
  persistedReducer,//createRootReducer(history), // root reducer with router state
  {}, //preloadedState,
  compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      ...middlewares
    ),
  ),
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>

export type AppDispach = typeof store.dispatch
