import {
    GET_ALL_TERM,
    ADD_TERM,
    UPDATE_TERM,
    DELETE_TERM
} from "../../constants/ActionTypes";

import { Term} from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    termsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const TermListFormater = (data: Term[]) => {
    const results = [{}]
    results.pop()
    data.map((termData: any) => {
        results.push(TermFormat(termData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value+'';
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }




export const TermFormat = (termData: Term) => {
    return {
        key: termData._id,
        pageName: termData.pageName||'',
        description : termData.description,

    }
}

const TermReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_TERM: {
            return {
                ...state,
                termsData: TermListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_TERM:
            const project = TermFormat(action.data);
            return {
                ...state,
                termData: updatedArray(state.termsData, project),
                showModal: false
            };

        case DELETE_TERM:
            const _TERM = action.data;
            return {
                ...state,
                termData: arrayReduce(state.termsData, _TERM),
                total: state.total-1,
                showModal: false
            };

        case ADD_TERM:
            let termData = !state.termsData ? [] : state.termsData;
            
            termData.unshift(TermFormat(action.data));
            return {
                ...state,
                termData: termData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default TermReducer;