import {
    GET_ALL_RECRUITMENTPROCESSPARAMETER,
    ADD_RECRUITMENTPROCESSPARAMETER,
    UPDATE_RECRUITMENTPROCESSPARAMETER,
    DELETE_RECRUITMENTPROCESSPARAMETER
} from "../../constants/ActionTypes";

import { RecruitmentProcessParameter, LocalItem } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    recruitmentProcessParametersData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const RecruitmentProcessParameterListFormater = (data: RecruitmentProcessParameter[]) => {
    const results = [{}]
    results.pop()
    data.map((recruitmentProcessParameterData: any) => {
        results.push(RecruitmentProcessParameterFormat(recruitmentProcessParameterData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value + '';
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
}

export const ItemFormat = (item: LocalItem) => {
    if (!item) {
        item = { title: '', description: '' }
    }
    const itemInfo = {
        key: item._id || '',
        title: item.title || '',
        description: epurator(item.description),
    }

    return itemInfo
}


export const ItemListFormater = (data: any) => {
    if (!data) {
        data = []
    }
    const results = [{}]
    results.pop()
    data.map((recruitmentProcessParameterData: any) => {
        results.push(ItemFormat(recruitmentProcessParameterData))
    })
    
    return results
}

export const ItemStringToArray = (data: { key?: string, description: string }[]) => {
    if (!data) {
        data = []
    }
    const results = ['']
    results.pop()
    data.map((strindData) => {
        results.push(strindData.description)
    })
    
    return results
}



export const RecruitmentProcessParameterFormat = (recruitmentProcessParameterData: RecruitmentProcessParameter) => {
    return {
        key: recruitmentProcessParameterData._id,
        title: recruitmentProcessParameterData.title || '',
        type: recruitmentProcessParameterData.type || '',
        description: epurator(recruitmentProcessParameterData.description || ''),
        itemNb: recruitmentProcessParameterData?.data?.length || 0,
        data: ItemListFormater(recruitmentProcessParameterData?.data || [])
    }
}

const RecruitmentProcessParameterReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_RECRUITMENTPROCESSPARAMETER: {
            return {
                ...state,
                recruitmentProcessParametersData: RecruitmentProcessParameterListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_RECRUITMENTPROCESSPARAMETER:
            let newUpdate = state.recruitmentProcessParametersData
            if (action.data) {
                const data = RecruitmentProcessParameterFormat(action.data);
                newUpdate = updatedArray(state.recruitmentProcessParametersData, data)
            }
            return {
                ...state,
                recruitmentProcessParameterData: newUpdate,
                showModal: false
            };

        case DELETE_RECRUITMENTPROCESSPARAMETER:
            const _RECRUITMENTPROCESSPARAMETER = action.data;
            return {
                ...state,
                recruitmentProcessParameterData: arrayReduce(state.recruitmentProcessParametersData, _RECRUITMENTPROCESSPARAMETER),
                total: state.total-1,
                showModal: false
            };

        case ADD_RECRUITMENTPROCESSPARAMETER:
            let recruitmentProcessParameterData = !state.recruitmentProcessParametersData ? [] : state.recruitmentProcessParametersData;
            
            if (action.data) {
                recruitmentProcessParameterData.unshift(RecruitmentProcessParameterFormat(action.data));
            }
            return {
                ...state,
                recruitmentProcessParameterData: recruitmentProcessParameterData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default RecruitmentProcessParameterReducer;