import {
    GET_ALL_PROPOSAL,
    ADD_PROPOSAL,
    UPDATE_PROPOSAL,
    DELETE_PROPOSAL
} from "../../constants/ActionTypes";

import { Proposal, SampleItem } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    proposalsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const ProposalListFormater = (data: Proposal[]) => {
    const results = [{}]
    results.pop()
    data.map((proposalData: any) => {
        results.push(ProposalFormat(proposalData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }

  export const ItemFormat = (item: SampleItem) => {
    if (!item) {
        item = {icon:'',  title:'', description:''}
    }
    const itemInfo = {
        key:item._id||'',
        icon: item.icon||'',
        title: item.title||'',
        description: epurator(item.description),
    }

    return itemInfo
}
export const ItemListFormater = (data: any) => {
    if(!data){
        data=[]
    }
    const results = [{}]
    results.pop()
    data.map((proposalData: any) => {
        results.push(ItemFormat(proposalData))
    })
    
    return results
}


export const ProposalFormat = (proposalData: Proposal) => {
    return {
        key: proposalData._id,
        pageName: proposalData.pageName||'',
        title: proposalData.title||'',
        image:proposalData.image||'',
        itemNb : proposalData?.data?.length||0,
        data: ItemListFormater(proposalData?.data)
    }
}

const ProposalReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_PROPOSAL: {
            return {
                ...state,
                proposalsData: ProposalListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_PROPOSAL:
            const project = ProposalFormat(action.data);
            return {
                ...state,
                proposalData: updatedArray(state.proposalsData, project),
                showModal: false
            };

        case DELETE_PROPOSAL:
            const _PROPOSAL = action.data;
            return {
                ...state,
                proposalData: arrayReduce(state.proposalsData, _PROPOSAL),
                total: state.total-1,
                showModal: false
            };

        case ADD_PROPOSAL:
            let proposalData = !state.proposalsData ? [] : state.proposalsData;
            
            proposalData.unshift(ProposalFormat(action.data));
            return {
                ...state,
                proposalData: proposalData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default ProposalReducer;