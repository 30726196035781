import {
    GET_ALL_ENGINEER_TYPES,
    ADD_ENGINEER_TYPES,
    DELETE_ENGINEER_TYPES,
    UPDATE_ENGINEER_TYPES,
    SHOW_MODAL
} from "../../constants/ActionTypes";

const INIT_STATE = {
    engineersTypes: [],
    showModal: false,
    total: 0
  };

const updatedArray = (array:any, element:any) => {
    let index = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i]._id === element._id) {
        array[i] = element;
        index = i;
        break;
      }
    }
    return array;
  };
  
  const arrayReduce = (array:any, element:any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
      tab.splice(index, 1);
    }
    return tab;
  };

  const EngineerTypesReducer = (state = INIT_STATE, action:any) => {
    switch (action.type) {
  
  
      case GET_ALL_ENGINEER_TYPES: {
        return {
          ...state,
          engineersTypes: action.result,
          total: action.total
        }
      }
  
      case UPDATE_ENGINEER_TYPES:
        const engineer = action.data;
        console.warn(engineer)
        return {
          ...state,
          engineersTypes: updatedArray(state.engineersTypes, engineer),
          showModal: false
        };
  
      case DELETE_ENGINEER_TYPES:
        const _engineer = action.data;
        return {
          ...state,
          engineersTypes: arrayReduce(state.engineersTypes, _engineer),
          total: state.total-1,
          showModal: false
        };
  
      case ADD_ENGINEER_TYPES:
        let engineersTypes:any = !state.engineersTypes ? [] : state.engineersTypes;
        //console.warn(action)
        engineersTypes.unshift(action.data);
        return {
          ...state,
          engineersTypes: engineersTypes,
          total: state.total+1,
          showModal: false
        };
  
      case SHOW_MODAL:
        const _show = action.data;
        return {
          ...state,
          showModal: _show,
        };

      default:
        return state;
    }
  }
  
  export default EngineerTypesReducer;