import { Options, ChoiceReason, PaginationOptions } from '../utils/DataSchemas'
import request from '../utils/request'

export default class ChoiceReasonService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (paginationWithSearchOption: PaginationOptions) => {
    let query = ''

    if (paginationWithSearchOption.offset !== null) {
      query
        ? (query += `&offset=${paginationWithSearchOption.offset}`)
        : (query += `?offset=${paginationWithSearchOption.offset}`)
    }

    if (paginationWithSearchOption.limit) {
      query
        ? (query += `&limit=${paginationWithSearchOption.limit}`)
        : (query += `?limit=${paginationWithSearchOption.limit}`)
    }
    if (paginationWithSearchOption.search) {
      query
        ? (query += `&search=${paginationWithSearchOption.search}`)
        : (query += `?search=${paginationWithSearchOption.search}`)
    }

    return request('GET', `choiceReasons/${query}`, this.options)
  }

  async create(data: ChoiceReason) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `choiceReasons/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `choiceReasons/${id}`, this.options)
  }

  async update(id: string, data: ChoiceReason) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PATCH', `choiceReasons/${id}`, requestOptions)
  }

  async delete(id: string) {
    return request('DELETE', `choiceReasons/${id}`, this.options)
  }
}
