import React, { useState } from "react";
import { Button, Layout, Popover } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import currencyData from "./currencyData";
import { switchLanguage, toggleCollapsedSideNav, switchCurrency } from "../../appRedux/actions";
import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";


import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
  THEME_TYPE_SEMI_DARK,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { setThemeType } from "./../../appRedux/actions/Setting";
import { MdOutlineLightMode } from "react-icons/md";
import { BsMoonFill } from "react-icons/bs";
import { GiMoon } from "react-icons/gi";
import { RootState } from "../../appRedux/store";


const { Header } = Layout;

const Topbar = () => {

  const { locale, navStyle, themeType, currency } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const notifications = useSelector(({notifications}: RootState) => notifications.notifications);

  const languageMenu = () => (
    <div>
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </div>
  );

  const currencyMenu = () => (
    <div>
      <ul className="gx-sub-popover">
        {currencyData.map(currency =>
          <li className="gx-media gx-pointer" key={JSON.stringify(currency)} onClick={() =>
            dispatch(switchCurrency(currency))
          }>
            <span className="gx-currency-text">{currency.name} ({currency.Symbol})</span>
          </li>
        )}
      </ul>
    </div>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  const onThemeTypeChange = (themeValue) => {
    dispatch(setThemeType(themeValue));
  };

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={("/assets/images/logo-noir.png")} style={{ width: 100 }} /></Link>

      <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
        placeholder="Search in app..."
        onChange={updateSearchChatUser}
        value={searchText} />
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
            <SearchBox styleName="gx-popover-search-bar"
              placeholder="Search in app..."
              onChange={updateSearchChatUser}
              value={searchText} />
          } trigger="click">
            <span className="gx-pointer gx-d-block"><i className="icon icon-search-new" /></span>
          </Popover>
        </li>
        {false && width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-notify" style={{display: 'none'}}>
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification />}
                trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification" /></span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                content={<MailNotification />} trigger="click">
                <span className="gx-pointer gx-status-pos gx-d-block">
                  {/*<i className="icon icon-chat-new" />*/}
                  <i className="icon icon-notification" />
                  {/*notifications?.length > 0 && <span className="gx-status gx-status-rtl gx-small gx-orange" >{notifications.length}</span>*/}
                  {notifications?.length > 0 &&
                  <span className="gx-status gx-status-rtl gx-small gx-orange text-xs text-white" 
                    style={{fontSize: "10px", textAlign: "center", height: "15px", width: "15px"}}>
                    {notifications.length < 10 ? notifications.length : ("9+")}
                  </span>}
                </span>
              </Popover>
            </li>
          </Auxiliary>
        }
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={currencyMenu()}
            trigger="click">
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              {currency?.name} ({currency?.Symbol})
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
            trigger="click">
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className="gx-pl-2 gx-language-name">{locale.name}</span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>

        <li>
          {themeType === THEME_TYPE_SEMI_DARK ? (
            <GiMoon
              size={25}
              color="black"
              onClick={() => onThemeTypeChange("THEME_TYPE_DARK")}
            />

          ) : null}

          {themeType === THEME_TYPE_DARK ? (
            <BsMoonFill
              size={25}
              color="yellow"
              onClick={() => onThemeTypeChange("THEME_TYPE_LITE")}
            />
          ) : null}
          {themeType === THEME_TYPE_LITE ? (

            <MdOutlineLightMode
              size={25}
              color="black"
              onClick={() => onThemeTypeChange("THEME_TYPE_SEMI_DARK")}
            />
          ) : null}
        </li>
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav cursor-pointer"><UserInfo /></li>
          </Auxiliary>
        }
      </ul>
    </Header>
  );
};

export default Topbar;
