import { Options, PaginationOptions } from '../utils/DataSchemas'
import request from '../utils/request'



function getPaginationQuery(PaginationOptions: PaginationOptions)
{
  let query = `?offset=${PaginationOptions.offset}&limit=${PaginationOptions.limit}&search=${PaginationOptions.search}`
  return query
}


export default class CompanyMessageService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (PaginationOptions:PaginationOptions) => {
    const query = getPaginationQuery(PaginationOptions)
    return request('GET', `company-messages/${query}`, this.options)
  }

  getById = async (CompanyMessageId: string) => {
    return request('GET', `company-messages/${CompanyMessageId}`, this.options)
  }
  
  create = async (data: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `company-messages`, requestOptions)
  }

  update = async (id: string, req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('PATCH', 'company-messages/' + id, requestOptions)
  }

  delete = async (id: string) => {
    const requestOptions: Options = {
      ...this.options
    }
    return request('DELETE', 'company-messages/' + id, requestOptions)
  }
}
