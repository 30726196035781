import {
  GET_ALL_ADMIN,
  ADD_ADMIN,
  UPDATE_ADMIN,
  DELETE_ADMIN
} from "../../constants/ActionTypes";
import {errorNotify} from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

const client = getBidiffySdkClient();

export function onGetAllAdmin(data: any) {
  return (dispatch: any) => {
    client.getAllUsers('admin', data).then((response) => {
      dispatch({
        type: GET_ALL_ADMIN,
        result: response.users,
        total: response.count || 0
      })
    })
      .catch(error => {
        errorNotify("Error")
      })
  }
}

export const onAddAdmin = (Admin: any) => {
  return (dispatch: any) => {
    dispatch({
      type: ADD_ADMIN,
      data: Admin
    })
  }
}


export const onUpdateAdmin = (Admin: any) => {
  return (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_ADMIN,
        data: Admin
      })
    } catch (error) {
      errorNotify("error")
    }
  }
}

export const onDeleteAdmin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: DELETE_ADMIN,
      data: data
    })
  }
}


