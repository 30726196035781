import {
    GET_ALL_TERM,
    ADD_TERM,
    UPDATE_TERM,
    DELETE_TERM
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllTerm = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllTerms(data).then((response) => {
           
            dispatch({
                type: GET_ALL_TERM,
                result: response.terms,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddTerm = (process: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_TERM,
            data: process
        });
    }
};


export const onUpdateTerm = (process: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_TERM,
                data: process
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteTerm = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_TERM,
            data: data
        });
    };
};


