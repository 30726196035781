import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  hideAuthLoader
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { useBidiffySdkClient } from "../util/customHooks";


const RecoverPassword = () => {
  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const [load,setLoad] = useState(false)
  const [status,setStatus] = useState(0)
  const history = useHistory();
  const client = useBidiffySdkClient();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  const onSubmit = (e:any) => e.preventDefault();

  const onFinishFailed = (errorInfo:any) => {
  };

  const onFinish = (values:any) => {
    setLoad(true)
    dispatch(showAuthLoader());
    client.recoverPassword(values).then(user => {
      dispatch(hideAuthLoader());
      setLoad(false)
      setStatus(1)
    }).catch(err => {
      dispatch(hideAuthLoader());
      setLoad(false)
      setStatus(2)
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg" >
              <div style={{backgroundColor: '#263238', width: '100%', height: '100%'}}/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.forgotPassword"/></h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo-white.png" width="120"/>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true}}
              name="basic"
	            onSubmit={onSubmit}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <p><IntlMessages id="app.userAuth.forgot"/></p>
              <Form.Item
                initialValue=""
                placeholder="demo@example.com"
                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                <Input placeholder="Email"/>
              </Form.Item>
              {status ?
              <div style={{marginTop: 10,marginBottom:30}}>
                {status === 1 ?
                <span className="text-primary">
                  <IntlMessages id="app.userAuth.sentPassword"/>
                </span>:
                <span style={{color:'red'}}>
                  <IntlMessages id="app.userAuth.ResetPasswordEmailNotFound"/>
                </span>
              }
              </div>:
              <div style={{marginTop: 50}}/>}
              <Form.Item>
               {!load?<Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.requestPassword"/>
                </Button>
                :
                <Button type="primary" className="gx-mb-0" >
                  <IntlMessages id="app.userAuth.processing"/>
                </Button>}
                <Link to="/signin"><IntlMessages id="app.userAuth.signIn"/>?</Link>
              </Form.Item>
              <span
                className="gx-text-light gx-fs-sm"> Copyright Bidiffy © 2022</span>
            </Form>
          </div>

         
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
