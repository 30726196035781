import { Contrat, Options, ContractPagination } from '../utils/DataSchemas'
import request from '../utils/request'

export default class ContratService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (contractPagination: ContractPagination) => {
    let query = '';

    if (contractPagination.offset !== null) {
      query
        ? (query += `&offset=${contractPagination.offset}`)
        : (query += `?offset=${contractPagination.offset}`)
    }

    if (contractPagination.limit) {
      query
        ? (query += `&limit=${contractPagination.limit}`)
        : (query += `?limit=${contractPagination.limit}`)
    }
      if (contractPagination.search) {
      query
        ? (query += `&search=${contractPagination.search}`)
        : (query += `?search=${contractPagination.search}`)
    }
    query+=`&developer=${contractPagination.developer}&status=${contractPagination.status}`
    

    return request('GET', `contrat/${query}`, this.options)
  }

  async create(data: Contrat) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `contrat/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `contrat/${id}`, this.options)
  }

  async update(id: string, data: Contrat) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `contrat/${id}`, requestOptions)
  }

  async delete(id: string) {
    return request('DELETE', `contrat/${id}`, this.options)
  }
}
