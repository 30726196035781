import {
    GET_ALL_OFFER,
    ADD_OFFER,
    UPDATE_OFFER,
    DELETE_OFFER
} from "../../constants/ActionTypes";

import { Offer, OfferView, Developer } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    offersData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const OfferListFormater = (data: Offer[]) => {
    const results = [{}]
    results.pop()
    data.map((offerData: any) => {
        results.push(OfferFormat(offerData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
}



export const GetDataDeveloper = (el: any) => {
    let user: any = el?.dev?.user
    if (!user) {
        user = {}
    }

    return {
        key: el?.dev?._id || '',
        email: user.email || '',
        lastname: user.lastname || '',
        firstname: user.firstname || '',
        hourRate: el?.hourRate||0
    }
}


export const GetDeveloper = (el: any) => {
    let user: any = el?.user
    if (!user) {
        user = {}
    }

    return {
        key: el._id || '',
        email: user.email || '',
        lastname: user.lastname || '',
        firstname: user.firstname || ''
    }
}


const GetDeveloperListFormater = (data: any[]) => {
    const results = [{}]
    results.pop()
    data.map((offerData: any) => {
        results.push(GetDataDeveloper(offerData))
    })
    
    return results
}

export const OfferFormat = (offerData: OfferView) => {
    let baselink = process.env.REACT_APP_COMPAGNIES_OFFER_BASE_WEBISTE_URL || ''
    return {
        key: offerData._id,
        title: offerData.title || '',
        description: offerData.description || '',
        link: baselink + offerData.link || '',
        linkcode: offerData.link || '',
        currency: offerData.currency || 'EUR',
        companies: CompanytoSelectList(offerData?.companies || []),
        companiesList: getCompaniesList(offerData?.companies || []),
        startDate: offerData.startDate || '',
        endDate: offerData.endDate || '',
        data: GetDeveloperListFormater(offerData.data),
        itemNb: offerData?.data?.length || 0,
    }
}

export const CompanytoSelectList = (data: any[]) => {
    let results: any = [];
    if (!data) {
        data = []
    }
    data.map((el) => {
        results.push({ label: el, value: el })
    })
    return results
}

export const CompanySelectToArray = (data: any[]) => {
    let results: any = [];
    if (!data) {
        data = []
    }
    data.map((el) => {
        results.push(el.value)
    })
    return results
}

const getCompaniesList = (param: any[]) => {
    let result = ""
    if (!param) {
        param = []
    }
    param.map((el) => {
        result += el + " | "
    })
    return result.substring(0, result.length - 2)

}

const OfferReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_OFFER: {
            return {
                ...state,
                offersData: OfferListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_OFFER:
            const project = OfferFormat(action.data);
            return {
                ...state,
                offerData: updatedArray(state.offersData, project),
                showModal: false
            };

        case DELETE_OFFER:
            const _OFFER = action.data;
            return {
                ...state,
                offerData: arrayReduce(state.offersData, _OFFER),
                total: state.total-1,
                showModal: false
            };

        case ADD_OFFER:
            let offerData = !state.offersData ? [] : state.offersData;
            
            offerData.unshift(OfferFormat(action.data));
            return {
                ...state,
                offerData: offerData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default OfferReducer;