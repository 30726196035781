import {
    GET_ALL_HEADER,
    ADD_HEADER,
    UPDATE_HEADER,
    DELETE_HEADER
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllHeader= (data: any) => {
    return (dispatch: any) => {
        
        client.getAllHeaders(data).then((response) => {
           
            dispatch({
                type: GET_ALL_HEADER,
                result: response.headers,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddHeader= (header: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_HEADER,
            data: header
        });
    }
};


export const onUpdateHeader= (header: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_HEADER,
                data: header
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteHeader= (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_HEADER,
            data: data
        });
    };
};


