import {
    GET_ALL_ADMIN,
    ADD_ADMIN,
    UPDATE_ADMIN,
    DELETE_ADMIN
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    adminsData: <any>[],
    showModal: false,
    total: 0
  };
  
  const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].key === element.key) {
        
        array[i] = element;
        index = i;
        break;
      }
    }
    return array;
  };
  
  const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
      tab.splice(index, 1);
    }
    return tab;
  };
  
  
  
  export const AdminFormat = (data:any) =>{
    return getAdmin(GetUser(data))
  }
  
  const GetBankAccount = (bankAccount: any) => {
    if (!bankAccount) {
      bankAccount = {}
    }
    const bankAccountData = {
      title: bankAccount.title || '',
      description: bankAccount.description || '',
      iban: bankAccount.iban || '',
      swiftCode: bankAccount.swiftCode || '',
      accountNumber: bankAccount.accountNumber || '',
      bankName: bankAccount.bankName || '',
      country: bankAccount.country|| ''
    }
    return bankAccountData
  }
  const GetUser = (user: any) => {
    
  
    if (!user) {
      user = {}
    }
  
    const userInfo = {
      _id: user._id || '',
      bankAccount: GetBankAccount(user.bankAccount),
      role: user.role || '',
      address: user.address || '',
      type_account: user.type_account || '',
      media: user.media || '',
      email: user.email || '',
      password: user.password || 'admin-bidiffy',
      phone_number: user.phone_number || '',
      lastname: user.lastname || '',
      firstname: user.firstname || '',
      isDeleted: user.isDeleted || false,
    }
  
    return userInfo
  }

  const getAdmin = (userData:any) =>{
    if (!userData) {
      userData = {}
    }
    const admin ={
      key: userData._id,
      isDeleted:userData.isDeleted||false,
      firstname: userData.firstname,
      email: userData.email,
      lastname: userData.lastname,
      address: userData.address,
      role: userData.role,
      type_account:userData.type_account,
      phone: userData.phone_number,
      bankName: userData.bankAccount.bankName,
      bankTitle: userData.bankAccount.title,
      bankSwiftCode: userData.bankAccount.swiftCode,
      bankIban: userData.bankAccount.iban,
      bankAccountNumber: userData.bankAccount.accountNumber,
      country: userData.bankAccount.country,
      attachement: userData.media,
      password:userData.password,
      bankDescription: userData.bankAccount.description
    }
    return admin
  }
  const CompanyListFormater = (data: any) => {
    const results = [{}]
    results.pop()
    data.map((userData: any) => {
      results.push(getAdmin(GetUser(userData)))
    })
    
    return results
  }
  
  

  
  const CompanyReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
      case GET_ALL_ADMIN: {
        return {
          ...state,
          adminsData: CompanyListFormater(action.result),
          total: action.total
        }
      }
  
      case UPDATE_ADMIN:
        const admin = getAdmin(GetUser(GetUser(action.data)));
        return {
          ...state,
          adminsData: updatedArray(state.adminsData, admin),
          showModal: false
        };
  
      case DELETE_ADMIN:
        const _ADMIN = action.data;
        return {
          ...state,
          adminsData: arrayReduce(state.adminsData, _ADMIN),
          total: state.total-1,
          showModal: false
        };
  
      case ADD_ADMIN:
        let adminsData = !state.adminsData ? [] : state.adminsData;
        
        adminsData.unshift(getAdmin(GetUser(action.data)));
        return {
          ...state,
          adminsData: adminsData,
          total: state.total+1,
          showModal: false
        };
  
      default:
        
        return state;
    }
  }
  
  export default CompanyReducer;