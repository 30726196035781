import { Options, PaginationOptions, TechnologyRequest } from '../utils/DataSchemas'
import request from '../utils/request'



function getPaginationQuery(paginationOption: PaginationOptions)
{
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}`
  return query
}

export default class TechnologyService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  createTechnology = async (data: TechnologyRequest) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `technologies`, requestOptions)
  }

  getAllTechnologies = async (paginationOption: PaginationOptions) => {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `technologies/${query}`, this.options)
  }

  getSearhTechnologies = async (paginationOption: PaginationOptions) => {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `technologies/${query}`, this.options)
  }

  getSingleTechnology = async (technologyId: number,) => {
    return request('GET', `technologies/${technologyId}`, this.options)
  }

  updateTechnology = async (technologyId: number, data: TechnologyRequest) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('PUT', `technologies/${technologyId}`, requestOptions)
  }

  deleteTechnology = async (technologyId: number,) => {

    return request('DELETE', `technologies/${technologyId}`, this.options)
  }

}
