import {
    GET_ALL_PROJECT,
    ADD_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

const client = getBidiffySdkClient();

export const onGetAllProjects = (data: any) => {
    return (dispatch: any) => {

        client.getAllProjects(data).then((response) => {
            
            dispatch({
                type: GET_ALL_PROJECT,
                result:  response.results,
                total: response.total
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddProject = (project: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_PROJECT,
            data: project
        });
    }
};


export const onUpdateProject = (project: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_PROJECT,
                data: project
            });
        } catch (error) {
            errorNotify("error")
            
        }
        
    }
};

export const onDeleteProject = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_PROJECT,
            data: data
        });
    };
};


