import { Options, PaginationOptions } from '../utils/DataSchemas'
import request from '../utils/request'

export default class NotificationService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (
    paginationOption: PaginationOptions
  ) => {
    const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}`
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `notifications${query}`, requestOptions)
  }

  getById = async (id: string) => {
    return request('GET', `notifications/${id}`, this.options)
  }

  create = async (data: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `notifications`, requestOptions)
  }

  update = async (id: string, req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('PATCH', 'notifications/' + id, requestOptions)
  }

  delete = async (id: string) => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('DELETE', 'notifications/' + id, requestOptions)
  }

}