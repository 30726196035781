import {
    GET_ALL_PATNER,
    ADD_PATNER,
    UPDATE_PATNER,
    DELETE_PATNER
} from "../../constants/ActionTypes";

import { Patner} from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    patnersData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const PatnerListFormater = (data: Patner[]) => {
    const results = [{}]
    results.pop()
    data.map((patnerData: any) => {
        results.push(PatnerFormat(patnerData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }





export const PatnerFormat = (patnerData: Patner) => {
    return {
        key: patnerData._id,
        name: patnerData.name||'',
        logo: patnerData.logo||'',
    }
}

const PatnerReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_PATNER: {
            return {
                ...state,
                patnersData: PatnerListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_PATNER:
            const project = PatnerFormat(action.data);
            return {
                ...state,
                patnerData: updatedArray(state.patnersData, project),
                showModal: false
            };

        case DELETE_PATNER:
            const _PATNER = action.data;
            return {
                ...state,
                patnerData: arrayReduce(state.patnersData, _PATNER),
                total: state.total-1,
                showModal: false
            };

        case ADD_PATNER:
            let patnerData = !state.patnersData ? [] : state.patnersData;
            
            patnerData.unshift(PatnerFormat(action.data));
            return {
                ...state,
                patnerData: patnerData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default PatnerReducer;