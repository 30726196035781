import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../util/asyncComponent";
import { ToastContainer } from 'react-toastify';

const App = ({ match }: any) => (
  <div className="gx-main-content-wrapper">
    <ToastContainer />
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard/index'))} />
      <Route path={`${match.url}developers`} component={asyncComponent(() => import('./Users/Developers/index'))} />
      <Route path={`${match.url}companies`} component={asyncComponent(() => import('./Users/Companies/index'))} />
      <Route path={`${match.url}projects`} component={asyncComponent(() => import('./Works/Projects/index'))} />
      <Route path={`${match.url}project`} component={asyncComponent(() => import('./Works/Projects/index'))} />
      <Route path={`${match.url}missions`} component={asyncComponent(() => import('./Works/Missions/index'))} />
      <Route path={`${match.url}mission`} component={asyncComponent(() => import('./Works/Missions/index'))} />
      <Route path={`${match.url}contracts`} component={asyncComponent(() => import('./Works/Contracts/index'))} />
      <Route path={`${match.url}contract`} component={asyncComponent(() => import('./Works/Contracts/index'))} />
      <Route path={`${match.url}finances`} component={asyncComponent(() => import('./Finances/Finances/index'))} />
      <Route path={`${match.url}finance`} component={asyncComponent(() => import('./Finances/Finances/index'))} />
      <Route path={`${match.url}invoices`} component={asyncComponent(() => import('./Finances/Invoices/index'))} />
      <Route path={`${match.url}invoice`} component={asyncComponent(() => import('./Finances/Invoices/index'))} />
      <Route path={`${match.url}technologies`} component={asyncComponent(() => import('./Globals/Technologies/index'))} />
      <Route path={`${match.url}engineer-types`} component={asyncComponent(() => import('./Globals/EngineerTypes'))} />
      <Route path={`${match.url}recruitment-process-parameters`} component={asyncComponent(() => import('./Globals/RecruitmentProcessParameter'))} />
      <Route path={`${match.url}text-messages`} component={asyncComponent(() => import('./Globals/TextMessage'))} />
      <Route path={`${match.url}adminProfile`} component={asyncComponent(() => import('./Admin/index'))} />
      <Route path={`${match.url}administrators`} component={asyncComponent(() => import('./Users/Admins/index'))} />
      <Route path={`${match.url}recruitment-process`} component={asyncComponent(() => import('./Globals/RecruitmentProcess'))} />
      <Route path={`${match.url}companies-messages`} component={asyncComponent(() => import('./CompanyMessages/index'))} />
      <Route path={`${match.url}proposals`} component={asyncComponent(() => import('./Website/Proposal/index'))} />
      <Route path={`${match.url}choice-reason`} component={asyncComponent(() => import('./Website/ChoiceReason/index'))} />
      <Route path={`${match.url}testimonials`} component={asyncComponent(() => import('./Website/Testimonial/index'))} />
      <Route path={`${match.url}process`} component={asyncComponent(() => import('./Website/Process/index'))} />
      <Route path={`${match.url}faqs`} component={asyncComponent(() => import('./Website/Faq/index'))} />
      <Route path={`${match.url}teams`} component={asyncComponent(() => import('./Website/Team/index'))} />
      <Route path={`${match.url}abouts`} component={asyncComponent(() => import('./Website/About/index'))} />
      <Route path={`${match.url}terms`} component={asyncComponent(() => import('./Website/Term/index'))} />
      <Route path={`${match.url}headers`} component={asyncComponent(() => import('./Website/Header/index'))} />
      <Route path={`${match.url}offers`} component={asyncComponent(() => import('./Offer/index'))} />
      <Route path={`${match.url}offer`} component={asyncComponent(() => import('./Offer/index'))} />
      <Route path={`${match.url}partners`} component={asyncComponent(() => import('./Patner/index'))} />
    
    </Switch>
  </div>
);

export default App;
