import { Options, PaginationWithSearchOptions } from '../utils/DataSchemas'
import request from '../utils/request'

export default class UserService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (
    typeAccount: string,
    paginationOptions: PaginationWithSearchOptions
  ) => {
    let query = `users?offset=${paginationOptions.offset}&limit=${paginationOptions.limit}&search=${paginationOptions.search}`

    if (typeAccount) {
      query += `&type_account=${typeAccount}`
    }

    return request('GET', query, this.options)
  }
  async getById(id: string) {
  
    return request('GET', `users/${id}`, this.options)
  }

  update = async (id: string, req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('PATCH', 'users/' + id, requestOptions)
  }

  delete = async (id: string) => {
    return request('DELETE', 'users/' + id, this.options)
  }

  CheckAvailableEmail = async (email: string) => {
    return request('GET', 'users/check/available/email?email=' + email, this.options)
  }
}
