import React from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const successNotify = (message) => {
    toast.success(message, {
        position: 'top-right'
    })
}


export const errorNotify = (message) => {
    toast.error(message??'Ok Error',{
        position: 'top-right'
    })
}

export const warningNotify = (message) => {
    toast.warn(message, {
        position: 'top-right'
    })
}
