import React from "react";
import {useDispatch, connect} from "react-redux";
import {Popover} from "antd";
import {userSignOut} from "../../appRedux/actions";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const getUploadUrl = process.env.REACT_APP_HH_API + '/upload/get/';
  // const user = useSelector(state => state.auth.authUser)
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/adminProfile">My Account</Link></li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );
  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <div style={{display: 'flex'}}>
          <div style={{textAlign:'center'}}>
            <Avatar
              name={props.user.firstname + ' ' + props.user.lastname}
              className="gx-size-40 gx-pointer gx-mr-3"
              round={true}
              size="40"
              style={{display: props.user.media ? 'none': 'block'}}
            />
            <img
              className="gx-rounded-full gx-size-40 gx-pointer gx-mr-3"
              style={{ width: 40, display: props.user.media ? 'block': 'none'}}
              alt="..."
              src={getUploadUrl + props.user.media}
            />
          </div>
          {
            !props.sidebarCollapsed
            &&
            
          <span className="gx-avatar-name" style={{display: "flex"}}>
          <span>
            <span>{ props.user ? (props.user.firstname + " " + props.user.lastname) : "" }</span>
            <div style={{textTransform: 'capitalize',  backgroundColor: 'white', borderRadius: 5, display: 'flex', justifyContent: 'center'}}>{props.user.role}</div>
          </span>
          <span>
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
          </span>
          </span>
}
        </div>
      </Popover>
    </div>
  )
};

const mapStateToProps = (state) => ({user: state.auth.authUser});
export default connect(mapStateToProps)(UserProfile);
// export default UserProfile;
