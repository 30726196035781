import { Options, CompanyPaginationOption } from '../utils/DataSchemas'
import request from '../utils/request'



function getPaginationQuery(companyPaginationOption: CompanyPaginationOption)
{
  let query = `?offset=${companyPaginationOption.offset}&limit=${companyPaginationOption.limit}&search=${companyPaginationOption.search}&searchType=${companyPaginationOption.searchType}`
  return query
}


export default class CompanyService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (companyPaginationOption:CompanyPaginationOption) => {
    const query = getPaginationQuery(companyPaginationOption)
    return request('GET', `companies/${query}`, this.options)
  }

  getById = async (companyId: number,) => {
    return request('GET', `companies/${companyId}`, this.options)
  }
  
  create = async (data: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `companies`, requestOptions)
  }

  update = async (id: string, req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('PATCH', 'companies/' + id, requestOptions)
  }

  delete = async (id: string) => {
    const requestOptions: Options = {
      ...this.options
    }
    return request('DELETE', 'companies/' + id, requestOptions)
  }
}
