import { Options } from '../utils/DataSchemas'
import request from '../utils/request'

export default class StatsService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getDevelopersCount = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/developers/count`, requestOptions)
  }

  getActiveDevelopersCount = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/developers/active/count`, requestOptions)
  }

  getNewDevelopersCount = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/developers/new/count`, requestOptions)
  }

  getProjectsCount = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/projects/count`, requestOptions)
  }

  getMissionsCount = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/missions/count`, requestOptions)
  }

  getCompaniesCount = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/companies/count`, requestOptions)
  }

  getTotalRevenues = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/finances/in`, requestOptions)
  }

  getTotalExpenses = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `stats/finances/out`, requestOptions)
  }

  getBestDevelopers = async (n: number) => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', 'stats/developers/best/' + n, requestOptions)
  }

  getBestProjects = async (n: number) => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', 'stats/projects/best/' + n, requestOptions)
  }

  getBestCompanies = async (n: number) => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', 'stats/companies/best/' + n, requestOptions)
  }

  history(from: Date, to: Date) {
    const requestOptions: Options = {
      ...this.options,
      data: { from, to }
    }
    return request('POST', 'stats/finances/history', requestOptions)
  }
}
