import {
    GET_ALL_FAQ,
    ADD_FAQ,
    UPDATE_FAQ,
    DELETE_FAQ
} from "../../constants/ActionTypes";

import { Faq, FaqItem } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    faqsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const FaqListFormater = (data: Faq[]) => {
    const results = [{}]
    results.pop()
    data.map((faqData: any) => {
        results.push(FaqFormat(faqData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value+'';
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }

  export const ItemFormat = (item: FaqItem) => {
    if (!item) {
        item = { title:'', description:'', num:1}
    }
    const itemInfo = {
        key:item._id||'',
        title: item.title||'',
        num: item.num||1,
        description: epurator(item.description),
    }

    return itemInfo
}


export const ItemListFormater = (data: any) => {
    if(!data){
        data=[]
    }
    const results = [{}]
    results.pop()
    data.map((faqData: any) => {
        results.push(ItemFormat(faqData))
    })
    
    return results
}

export const ItemStringToArray =(data: {key?:string,description:string}[]) => {
    if(!data){
        data=[]
    }
    const results = ['']
    results.pop()
    data.map((strindData) => {
        results.push(strindData.description)
    })
    
    return results
}



export const FaqFormat = (faqData: Faq) => {
    return {
        key: faqData._id,
        pageName: faqData.pageName||'',
        itemNb : faqData?.data?.length||0,
        data:ItemListFormater(faqData?.data||[])
    }
}

const FaqReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_FAQ: {
            return {
                ...state,
                faqsData: FaqListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_FAQ:
            const project = FaqFormat(action.data);
            return {
                ...state,
                faqData: updatedArray(state.faqsData, project),
                showModal: false
            };

        case DELETE_FAQ:
            const _FAQ = action.data;
            return {
                ...state,
                faqData: arrayReduce(state.faqsData, _FAQ),
                total: state.total-1,
                showModal: false
            };

        case ADD_FAQ:
            let faqData = !state.faqsData ? [] : state.faqsData;
            
            faqData.unshift(FaqFormat(action.data));
            return {
                ...state,
                faqData: faqData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default FaqReducer;