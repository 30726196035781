import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  Briefcase, Cpu, DollarSign, FileText, FolderMinus, Layers, Monitor,
  UserCheck, CheckSquare, BookOpen, Package, CheckCircle, Users, Smile,
  HelpCircle, Wind, Settings, Sliders, MessageCircle, Gift
} from 'react-feather';
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import { useDispatch, useSelector } from "react-redux";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const user = useSelector((state: any) => state.auth.authUser)
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile sidebarCollapsed={sidebarCollapsed} />
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <MenuItemGroup key="main" className="gx-menu-group" title={<IntlMessages id="sidebar.main" />}>
              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-widgets" />
                  <span><IntlMessages id="sidebar.dashboard" /></span>
                </Link>
              </Menu.Item>

              <SubMenu key="users" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={<span> <i className="icon icon-user" /> <span><IntlMessages id="sidebar.users" /></span></span>
                }>
                <Menu.Item key="administrators/list">
                  <Link to="/administrators/list"><i className="icon icon-auth-screen" />
                    <span><IntlMessages id="sidebar.administrators" /></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="developers">
                  <Link to="/developers"><i className="icon icon-profile2" />
                    <span><IntlMessages id="sidebar.developers" /></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="companies">
                  <Link to="/companies"><i className="icon icon-company" />
                    <span><IntlMessages id="sidebar.companies" /></span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu key="works" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={<span> <Briefcase size={21} className="icon" style={{ marginTop: 8 }} /> <span><IntlMessages id="sidebar.works" /></span></span>
                }>
                <Menu.Item key="projects">
                  <Link to="/projects"><FolderMinus size={19} className="icon" />
                    <span><IntlMessages id="sidebar.projects" /></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="missions">
                  <Link to="/missions"><Layers size={19} className="icon" />
                    <span><IntlMessages id="sidebar.missions" /></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="contracts">
                  <Link to="/contracts"><FileText size={19} className="icon" />
                    <span><IntlMessages id="sidebar.contracts" /></span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {user.role === "master" &&
                <SubMenu key="financess" popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={<span> <i className="icon icon-transfer" /> <span><IntlMessages id="sidebar.finances" /></span></span>
                  }>
                  <Menu.Item key="finances">
                    <Link to="/finances"><i className="icon icon-transfer" />
                      <span><IntlMessages id="sidebar.finances" /></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="invoices">
                    <Link to="/invoices"><DollarSign size={19} className="icon" />
                      <span><IntlMessages id="sidebar.invoices" /></span>
                    </Link>
                  </Menu.Item>

                </SubMenu>
              }
              <Menu.Item key="recruitment-process">
                <Link to="/recruitment-process"><Sliders size={19} className="icon" />
                  <span><IntlMessages id="sidebar.recruitmentProcesss" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="companies-messages">
                <Link to="/companies-messages"><i className="icon icon-chat" />
                  <span><IntlMessages id="sidebar.companiesMessages" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="offers">
                <Link to="/offers"><Gift size={19} className="icon" />
                  <span><IntlMessages id="sidebar.offers" /></span>
                </Link>
              </Menu.Item>



            </MenuItemGroup>

            <MenuItemGroup key="globals" className="gx-menu-group" title={<IntlMessages id="sidebar.globals" />}>
              <SubMenu key="globals" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={<span> <Monitor size={21} className="icon" style={{ marginTop: 8 }} /> <span><IntlMessages id="sidebar.globals" /></span></span>
                }>
                <Menu.Item key="technologies">
                  <Link to="/technologies"><Cpu size={19} className="icon" />
                    <span><IntlMessages id="sidebar.technologies" /></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="engineer-types">
                  <Link to="/engineer-types"><UserCheck size={19} className="icon" />
                    <span><IntlMessages id="sidebar.engineer_types" /></span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="recruitment-process-parameters">
                  <Link to="/recruitment-process-parameters"><Settings size={19} className="icon" />
                    <span><IntlMessages id="sidebar.recruitmentProcessParameters" /></span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="text-messages">
                  <Link to="/text-messages"><MessageCircle size={19} className="icon" />
                    <span><IntlMessages id="sidebar.textMessage" /></span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            </MenuItemGroup>
            {user.role === "master" &&
              <MenuItemGroup key="website" className="gx-menu-group" title={<IntlMessages id="sidebar.website" />}>
                <SubMenu key="website" popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={<span> <Monitor size={21} className="icon" style={{ marginTop: 8 }} /> <span><IntlMessages id="sidebar.website" /></span></span>
                  }>
                  <Menu.Item key="headers">
                    <Link to="/headers"><BookOpen size={19} className="icon" />
                      <span><IntlMessages id="sidebar.headers" /></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="terms">
                    <Link to="/terms"><BookOpen size={19} className="icon" />
                      <span><IntlMessages id="sidebar.terms" /></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="proposals">
                    <Link to="/proposals"><Package size={19} className="icon" />
                      <span><IntlMessages id="sidebar.proposals" /></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="choice-reason">
                    <Link to="/choice-reason"><CheckCircle size={19} className="icon" />
                      <span><IntlMessages id="sidebar.choiceReasons" /> </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="testimonials">
                    <Link to="/testimonials"><Smile size={19} className="icon" />
                      <span><IntlMessages id="sidebar.testimonials" /></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="process">
                    <Link to="/process"><CheckSquare size={19} className="icon" />
                      <span><IntlMessages id="sidebar.processs" /></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="faqs">
                    <Link to="/faqs"><HelpCircle size={19} className="icon" />
                      <span><IntlMessages id="sidebar.faqs" /></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="teams">
                    <Link to="/teams"><Users size={19} className="icon" />
                      <span><IntlMessages id="sidebar.teams" /></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="abouts">
                    <Link to="/abouts"><Wind size={19} className="icon" />
                      <span><IntlMessages id="sidebar.abouts" /></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="partners">
                    <Link to="/partners"><Users size={19} className="icon" />
                      <span><IntlMessages id="sidebar.patners" /></span>
                    </Link>
                  </Menu.Item>

                </SubMenu>
              </MenuItemGroup>
            }

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

