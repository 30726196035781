import {
    GET_ALL_FINANCE,
    ADD_FINANCE,
    UPDATE_FINANCE,
    DELETE_FINANCE
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();


export const onGetAllFinance = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllFinances(data).then((response) => {
           
            dispatch({
                type: GET_ALL_FINANCE,
                result: response.finances,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddFinance = (finance: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_FINANCE,
            data: finance
        });
    }
};


export const onUpdateFinance = (finance: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_FINANCE,
                data: finance
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteFinance = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_FINANCE,
            data: data
        });
    };
};


