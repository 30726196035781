import {
    GET_ALL_RECRUITMENTPROCESSPARAMETER,
    ADD_RECRUITMENTPROCESSPARAMETER,
    UPDATE_RECRUITMENTPROCESSPARAMETER,
    DELETE_RECRUITMENTPROCESSPARAMETER
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllRecruitmentProcessParameter = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllRecruitmentProcessParameters(data).then((response) => {
           
            dispatch({
                type: GET_ALL_RECRUITMENTPROCESSPARAMETER,
                result: response.recruitmentProcessParameters,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddRecruitmentProcessParameter = (recruitmentProcessParameter: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_RECRUITMENTPROCESSPARAMETER,
            data: recruitmentProcessParameter
        });
    }
};


export const onUpdateRecruitmentProcessParameter = (recruitmentProcessParameter: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_RECRUITMENTPROCESSPARAMETER,
                data: recruitmentProcessParameter
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteRecruitmentProcessParameter = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_RECRUITMENTPROCESSPARAMETER,
            data: data
        });
    };
};


