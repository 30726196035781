import {
    GET_ALL_HEADER,
    ADD_HEADER,
    UPDATE_HEADER,
    DELETE_HEADER
} from "../../constants/ActionTypes";

import { Header} from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    headersData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const HeaderListFormater = (data: Header[]) => {
    const results = [{}]
    results.pop()
    data.map((headerData: any) => {
        results.push(HeaderFormat(headerData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value+'';
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }




export const HeaderFormat = (headerData: Header) => {
    return {
        key: headerData._id,
        pageName: headerData.pageName||'',
        description : headerData.description||'',
        title: headerData.title||'',
        icon :  headerData.icon||'',

    }
}

const HeaderReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_HEADER: {
            return {
                ...state,
                headersData: HeaderListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_HEADER:
            const project = HeaderFormat(action.data);
            return {
                ...state,
                headerData: updatedArray(state.headersData, project),
                showModal: false
            };

        case DELETE_HEADER:
            const _HEADER = action.data;
            return {
                ...state,
                headerData: arrayReduce(state.headersData, _HEADER),
                total: state.total-1,
                showModal: false
            };

        case ADD_HEADER:
            let headerData = !state.headersData ? [] : state.headersData;
            
            headerData.unshift(HeaderFormat(action.data));
            return {
                ...state,
                headerData: headerData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default HeaderReducer;