import {
    GET_ALL_ABOUT,
    ADD_ABOUT,
    UPDATE_ABOUT,
    DELETE_ABOUT
} from "../../constants/ActionTypes";

import { About, AboutItem } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    aboutsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const AboutListFormater = (data: About[]) => {
    const results = [{}]
    results.pop()
    data.map((aboutData: any) => {
        results.push(AboutFormat(aboutData))
    })
    
    return results
}
export const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }

  export const ItemFormat = (item: AboutItem) => {
    if (!item) {
        item = {title:'', description:''}
    }
    const itemInfo = {
        key:item._id||'',
        title: item.title||'',
        description: epurator(item.description),
    }

    return itemInfo
}
export const ItemListFormater = (data: any) => {
    if(!data){
        data=[]
    }
    const results = [{}]
    results.pop()
    data.map((aboutData: any) => {
        results.push(ItemFormat(aboutData))
    })
    
    return results
}


export const AboutFormat = (aboutData: About) => {
    return {
        key: aboutData._id,
        pageName: aboutData.pageName||'',
        title: aboutData.title||'',
        subtitle:aboutData.subtitle||'',
        description: aboutData.description||'',
        image:aboutData.image||'',
        itemNb : aboutData?.data?.length||0,
        data: ItemListFormater(aboutData?.data)
    }
}

const AboutReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_ABOUT: {
            return {
                ...state,
                aboutsData: AboutListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_ABOUT:
            const project = AboutFormat(action.data);
            return {
                ...state,
                aboutData: updatedArray(state.aboutsData, project),
                showModal: false
            };

        case DELETE_ABOUT:
            const _ABOUT = action.data;
            return {
                ...state,
                aboutData: arrayReduce(state.aboutsData, _ABOUT),
                total: state.total-1,
                showModal: false
            };

        case ADD_ABOUT:
            let aboutData = !state.aboutsData ? [] : state.aboutsData;
            
            aboutData.unshift(AboutFormat(action.data));
            return {
                ...state,
                aboutData: aboutData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default AboutReducer;