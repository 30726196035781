import { GET_CONTRATS, GET_ONE_CONTRAT} from "../../constants/ActionTypes";

    
    const INIT_STATE = {
      contrats: [],
      selectedItem: null
    };
    
    const ContratReducer = (state = INIT_STATE, action:any) => {
      switch (action.type) {
        case GET_CONTRATS: {
          return {
            ...state,
            contrats: action.payload.results
          }
        }
    
        case GET_ONE_CONTRAT: {
          return {
            ...state,
            selectedItem: action.payload
          }
        }
        default:
          return state;
      }
    }
    
    export default ContratReducer;
    