import {
    GET_ALL_TESTIMONIAL,
    ADD_TESTIMONIAL,
    UPDATE_TESTIMONIAL,
    DELETE_TESTIMONIAL
} from "../../constants/ActionTypes";

import { Testimonial, SampleItem } from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    testimonialsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const TestimonialListFormater = (data: Testimonial[]) => {
    const results = [{}]
    results.pop()
    data.map((testimonialData: any) => {
        results.push(TestimonialFormat(testimonialData))
    })
    
    return results
}
const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
}

export const ItemFormat = (item: SampleItem) => {
    if (!item) {
        item = { icon: '', title: '', description: '' }
    }
    const itemInfo = {
        key: item._id || '',
        icon: item.icon || '',
        title: item.title || '',
        description: epurator(item.description),
    }

    return itemInfo
}
export const ItemListFormater = (data: any) => {
    if (!data) {
        data = []
    }
    const results = [{}]
    results.pop()
    data.map((testimonialData: any) => {
        results.push(ItemFormat(testimonialData))
    })
    
    return results
}


export const TestimonialFormat = (testimonialData: Testimonial) => {
    return {
        key: testimonialData._id,
        pageName: testimonialData.pageName || '',
        logo: testimonialData.logo || '',
        name: testimonialData.name || '',
        profilPicture: testimonialData.profilPicture || '',
        profession: testimonialData.profession || '',
        description: epurator(testimonialData.description),

    }
}

const TestimonialReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_TESTIMONIAL: {
            return {
                ...state,
                testimonialsData: TestimonialListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_TESTIMONIAL:
            const project = TestimonialFormat(action.data);
            return {
                ...state,
                testimonialData: updatedArray(state.testimonialsData, project),
                showModal: false
            };

        case DELETE_TESTIMONIAL:
            const _TESTIMONIAL = action.data;
            return {
                ...state,
                testimonialData: arrayReduce(state.testimonialsData, _TESTIMONIAL),
                total: state.total-1,
                showModal: false
            };

        case ADD_TESTIMONIAL:
            let testimonialData = !state.testimonialsData ? [] : state.testimonialsData;
            
            testimonialData.unshift(TestimonialFormat(action.data));
            return {
                ...state,
                testimonialData: testimonialData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default TestimonialReducer;