import { Options, LoginRequest } from '../utils/DataSchemas'
import request from '../utils/request'

export default class AuthService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  login = async (req: LoginRequest) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/login`, requestOptions)
  }

  logout = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `auth/logout`, requestOptions)
  }

  me = async () => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `auth/me`, requestOptions)
  }

  refresh = async (refreshToken: string) => {
    const requestOptions: Options = {
      ...this.options,
      data: {refresh_token: refreshToken}
    }
    return request('POST', `auth/refresh`, requestOptions)
  }

  register = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/register`, requestOptions)
  }

  recoverPassword = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/password/recover`, requestOptions)
  }

  resetPassword = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/password/reset`, requestOptions)
  }

  changeEmail = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/email/change`, requestOptions)

  }

  sendAccountActivationEmail = async (email: string) => {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', 'auth/email/sendactivation/' + email, requestOptions)
  }
}