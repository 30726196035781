import {
  GET_ALL_ENGINEER_TYPES,
  ADD_ENGINEER_TYPES,
  UPDATE_ENGINEER_TYPES,
  DELETE_ENGINEER_TYPES,
  SHOW_MODAL
} from "../../constants/ActionTypes";
import { errorNotify, successNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();

export const onGetAllEngineeTypes = (data: any) => {
  return (dispatch) => {
    if (data?.isSearchRequest) {
      client.getAllEngineerTypes(data.data).then((response) => {
        dispatch({
          type: GET_ALL_ENGINEER_TYPES,
          result: response.results,
          total: response.total
        });
      })
        .catch(error => {
          errorNotify("Error")
        })
    } else {
      client.getAllEngineerTypes(data).then((response) => {
        dispatch({
          type: GET_ALL_ENGINEER_TYPES,
          result: response.results,
          total: response.count
        });
      })
        .catch(error => {
          errorNotify("Error!")
        })
    }
  };
};

export const onAddEngineeTypes = (engineer) => {
  return (dispatch) => {
    client.createEngineerType(engineer).then((response: any) => {
      if (response.status) {
        successNotify('Success!')
        dispatch({
          type: ADD_ENGINEER_TYPES,
          data: response.data
        });
      } else {
        errorNotify("Error!")
      }
    })

      .catch(error => {
        errorNotify("Please verify you form et submit!")
      })
  }
};


export const onUpdateEngineeTypes = (engineer: any) => {
  return (dispatch) => {
    client.updateEngineerType(engineer._id, engineer).then((response: any) => {
      if (response?.status) {
        
        successNotify('Success!')
        dispatch({
          type: UPDATE_ENGINEER_TYPES,
          data: response?.data
        });
      } else {
        errorNotify("Already Exisrs")
      }
    })
      .catch(error => {
        errorNotify("Please verify you form et submit!")
      })
  };
};

export const onDeleteEngineeTypes = (data) => {
  return (dispatch) => {
    client.deleteEngineerType(data._id).then((response) => {
      successNotify('Success!')
      dispatch({
        type: DELETE_ENGINEER_TYPES,
        data: data
      });
    })
      .catch(error => {
        errorNotify("Error!")
      })
  };
};

export const onShowModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      data: show
    });

  };
};