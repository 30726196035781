import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import RecoverPassword from "../RecoverPassword";
import { setInitUrl, getTechnology, onGetAllProjects, onGetAllCompany, getContrats, onNotificationSocket, onGetNotifications } from "../../appRedux/actions";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

// import { Client, types } from "@bidiffy/general-backend-sdk";
import { RootState } from "../../appRedux/store";
import io from "socket.io-client";
import { notificationInTitle } from "../../util/helpers";
import { epurator } from "../../GlobalComponents/UseFullFunctions";
import { useBidiffySdkClient } from "../../util/customHooks";


//const client = new Client();
//client.configure();


const NotificationWebSocket = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const client = useBidiffySdkClient();
  //const socket = useSelector(({ notifications }: RootState) => notifications?.socket);
  //const notifications = useSelector(({ notifications }: RootState) => notifications.notifications);
  

  useEffect(() => {
    // console.log("Notification component")
    const ws: any = null;
    if (location.pathname !== '/signin' && location.pathname !== '/signup') {
      let ws = io(process?.env?.REACT_APP_SOCKETIO_ADDR as string);
      onGetNotifications().then((data) => {
        dispatch(data)
        notificationInTitle(data.data);
      });
      ws.on('new-notification', async () => {
        const data = await onGetNotifications();
        if (data?.data?.length > 0) {
          if (window.Notification) {
            const makeNotification = function (data: any) {
              if (data && data.length > 0) {
                const n = data[0];
                const notif = new Notification(n.title, {
                  body: epurator(n.text),
                  icon: window.location.origin + "/w-logo.png",
                });
                notificationInTitle(data);
              }
            }

            if (Notification.permission != "granted") {
              Notification.requestPermission().then((status) => {
                if (status == "granted") {
                  makeNotification(data.data)
                }
              })
            } else {
              makeNotification(data.data)
            }
          }
        }
        dispatch(data);
      });
      dispatch(onNotificationSocket(true));
    }
    return () => { ws?.close(); dispatch(onNotificationSocket(false)) }
  }, [dispatch, location])
  
  return null;
}




const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />}
  />;

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};


let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);



const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);

  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const [size] = useState(20)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
      styleSheetLink.href = "/css/dark_theme.min.css";
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      styleSheetLink.href = "";
    }
  }, [themeType]);

  useEffect(async () => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }

    const params = {
      offset: 0,
      limit: 10,
      search: ''
    }

    // try {
    //   const response = await client.getAllContrats(params)
    //   dispatch(getContrats(response))
    // } catch (error) {
    //   ;
    // }

  });

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        history.push('/dashboard');
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);


  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <NotificationWebSocket />
        <Switch>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/recover-password' component={RecoverPassword} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default memo(App);
