import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  hideMessage,
  showAuthLoader,
  showAuthMessage,
  hideAuthLoader,
  userSignInSuccess,
  setRefreshToken,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import LoadingOverlay from 'react-loading-overlay';
import { RootState } from '../appRedux/store';
import { notificationInTitle } from "../util/helpers";
import { useBidiffySdkClient } from "../util/customHooks";
import {
  EyeOutlined, EyeInvisibleOutlined, EyeTwoTone
} from "@ant-design/icons";


const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }: RootState) => auth);
  const client = useBidiffySdkClient();

  const history = useHistory();
  const [isActive, setIsActive] = useState(false)
  const [viewPassword, setViewPassword] = useState(false)

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword)
    let password: any = document.querySelector('#id_password');
    const type = password?.getAttribute('type') === 'password' ? 'text' : 'password';
    password?.setAttribute('type', type);
  }

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setIsActive(false)
      }, 1500);
      setTimeout(() => {
        dispatch(hideMessage());

      }, 50);
    }
    if (authUser !== null) {
      history.push('/');
    }
    notificationInTitle([]);
  });

  const onSubmit = (e: any) => e.preventDefault();

  const onFinishFailed = (errorInfo: any) => {
  };

  const onFinish = (values: any) => {
    setIsActive(true);
    dispatch(showAuthLoader());
    client.login(values).then((token: any) => {
      dispatch(setRefreshToken(token.refresh_token))
      client.me().then((user: any) => {
        // dispatch(userSignIn(user));
        // dispatch(showAuthMessage(intl.formatMessage({ id: 'appModule.loginSuccessfully' })));
        if (user.type_account === 'admin') {
          dispatch(userSignInSuccess(user));
        } else {
          setIsActive(false)
          dispatch(showAuthMessage('Login failed'));
        }
        dispatch(hideAuthLoader());
      }).catch((err: any) => {
        dispatch(showAuthMessage('Login failed'));
      })
    }).catch((err: any) => {

      dispatch(showAuthMessage('Login failed'));
      // show login unsuccessful message
    });
  };


  return (
    <LoadingOverlay className="gx-app-login-wrap" active={isActive} spinner={<CircularProgress />}>

      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg" >
                <div style={{ backgroundColor: '#263238', width: '100%', height: '100%' }} />
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn" /></h1>
                <p><IntlMessages id="app.userAuth.bySigning" /></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src="/assets/images/logo-white.png" width="120" />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onSubmit={onSubmit}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0">

                <Form.Item
                  initialValue="demo@example.com"
                  rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  initialValue="demo#123"

                  rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                  <div className="flex">
                      <Input.Password
                        type="password"
                        id="id_password"
                        placeholder="input password"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                  </div>


                </Form.Item>
                <Form.Item>
                  <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                  <span className="gx-signup-form-forgot gx-link"><IntlMessages
                    id="appModule.termAndCondition" /></span>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  <Link to="/recover-password"><IntlMessages id="app.userAuth.forgotPassword" />?</Link>
                </Form.Item>
                <span
                  className="gx-text-light gx-fs-sm"> Copyright Bidiffy © {(new Date()).getFullYear()}</span>
              </Form>
            </div>

            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    </LoadingOverlay>

  );
};

export default SignIn;
