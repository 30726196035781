import {
    GET_ALL_OFFER,
    ADD_OFFER,
    UPDATE_OFFER,
    DELETE_OFFER
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();


export const onGetAllOffer = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllOffers(data).then((response) => {
           
            dispatch({
                type: GET_ALL_OFFER,
                result: response.offers,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddOffer = (offer: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_OFFER,
            data: offer
        });
    }
};


export const onUpdateOffer = (offer: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_OFFER,
                data: offer
            });
        } catch (error) {
            errorNotify("error")
            
        }

    }
};

export const onDeleteOffer = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_OFFER,
            data: data
        });
    };
};


