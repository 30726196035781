import { Options } from '../utils/DataSchemas'
import request from '../utils/request'

export default class UploadService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  upload = async (file: any) => {
    const form = new FormData()
    form.append('file', file)
    const requestOptions: Options = {
      ...this.options,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: form
    }
    return request('POST', 'upload/upload', requestOptions)
  }

  get = async (filename: string) => {
    const requestOptions: Options = {
      ...this.options
    }
    return request('GET', 'upload/get/' + filename, requestOptions)
  }

  delete = async (filename: string) => {
    const requestOptions: Options = {
      ...this.options
    }
    return request('DELETE', 'upload/' + filename, requestOptions)
  }
}
