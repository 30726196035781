import { Options, Project, ProjectPagination, PaginationOptions } from '../utils/DataSchemas'
import request from '../utils/request'

function getPaginationQuery(paginationOption: ProjectPagination) {
  const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}&company=${paginationOption.company}&status=${paginationOption.status}`
  return query
}

function getAllPaginationQuery(paginationOption: PaginationOptions) {
  const query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}&search=${paginationOption.search}`
  return query
}

export default class ProjectService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (paginationWithSearchOption: ProjectPagination) => {
    const query = getPaginationQuery(paginationWithSearchOption)
    return request('GET', `projects/${query}`, this.options)
  }

  getGeneral = async (paginationWithSearchOption: PaginationOptions) => {
    const query = getAllPaginationQuery(paginationWithSearchOption)
    return request('GET', `projects/all${query}`, this.options)
  }

  async create(data: Project) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `projects/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `projects/${id}`, this.options)
  }

  async update(id: string, data: Project) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PATCH', `projects/${id}`, requestOptions)
  }

  async delete(id: string) {
    return request('DELETE', `projects/${id}`, this.options)
  }
}
