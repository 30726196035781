import {
    GET_ALL_FAQ,
    ADD_FAQ,
    UPDATE_FAQ,
    DELETE_FAQ
} from "../../constants/ActionTypes";
import { errorNotify } from '../../GlobalComponents/ToastNotification';
import { getBidiffySdkClient } from "../../util/helpers";

// ** Configure SDK Client
const client = getBidiffySdkClient();


export const onGetAllFaq = (data: any) => {
    return (dispatch: any) => {
        
        client.getAllFaqs(data).then((response) => {
          
            dispatch({
                type: GET_ALL_FAQ,
                result:  response.faqs,
                total: response.count
            });
        })
            .catch(error => {
                errorNotify("Error")
                
            })
    };
};

export const onAddFaq = (faq: any) => {
    return (dispatch: any) => {
        dispatch({
            type: ADD_FAQ,
            data: faq
        });
    }
};


export const onUpdateFaq = (faq: any) => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: UPDATE_FAQ,
                data: faq
            });
        } catch (error) {
            errorNotify("error")
            
        }
        
    }
};

export const onDeleteFaq = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_FAQ,
            data: data
        });
    };
};


