import {
    GET_ALL_TEAM,
    ADD_TEAM,
    UPDATE_TEAM,
    DELETE_TEAM
} from "../../constants/ActionTypes";

import { Team} from "../../GlobalComponents/Interfaces"

const INIT_STATE = {
    teamsData: <any>[],
    showModal: false,
    total: 0
};


export const updatedArray = (array: any, element: any) => {
    
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};





const TeamListFormater = (data: Team[]) => {
    const results = [{}]
    results.pop()
    data.map((teamData: any) => {
        results.push(TeamFormat(teamData))
    })
    
    return results
}
const epurator = (value: any) => {
    let html = value;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
}




export const TeamFormat = (teamData: Team) => {
    return {
        key: teamData._id,
        username: teamData.username || '',
        name: teamData.name || '',
        profilPicture: teamData.profilPicture || '',
        profession: teamData.profession || '',
        twitterUrl: teamData.twitterUrl||'',
        linkedinUrl: teamData.linkedinUrl||'',
        description: epurator(teamData.description),

    }
}

const TeamReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_ALL_TEAM: {
            return {
                ...state,
                teamsData: TeamListFormater(action.result),
                total: action.total
            }
        }

        case UPDATE_TEAM:
            const project = TeamFormat(action.data);
            return {
                ...state,
                teamData: updatedArray(state.teamsData, project),
                showModal: false
            };

        case DELETE_TEAM:
            const _TEAM = action.data;
            return {
                ...state,
                teamData: arrayReduce(state.teamsData, _TEAM),
                total: state.total-1,
                showModal: false
            };

        case ADD_TEAM:
            let teamData = !state.teamsData ? [] : state.teamsData;
            
            teamData.unshift(TeamFormat(action.data));
            return {
                ...state,
                teamData: teamData,
                total: state.total+1,
                showModal: false
            };

        default:
            
            return state;
    }
}

export default TeamReducer;